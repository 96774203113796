import { type ReactNode } from 'react';
import { type HProps, StyledH1, StyledH2, StyledH3, StyledH4, StyledH5, StyledH6 } from './styles';

export enum HeadingType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum HeadingStyleType {
  Hero = 'hero',
  Heading1 = 'heading1',
  Heading2 = 'heading2',
  Heading3 = 'heading3',
  Heading4 = 'heading4',
  Heading5 = 'heading5',
  Heading6 = 'heading6',
  Heading7 = 'heading7',
}

type HeadingProps = HProps & {
  children?: ReactNode;
  headingType?: HeadingType;
};

function Heading({ headingType, ...props }: HeadingProps) {
  switch (headingType) {
    case HeadingType.H1:
      return <StyledH1 {...props} />;
    case HeadingType.H2:
      return <StyledH2 {...props} />;
    case HeadingType.H3:
      return <StyledH3 {...props} />;
    case HeadingType.H4:
      return <StyledH4 {...props} />;
    case HeadingType.H5:
      return <StyledH5 {...props} />;
    case HeadingType.H6:
      return <StyledH6 {...props} />;
    default:
      return <StyledH2 {...props} />;
  }
}

export default Heading;
