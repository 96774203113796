import styled from '@emotion/styled';
import spacing from '#assets/spacing';
import colors from '#assets/colors';

export const WarningWrapper = styled.div`
  background-color: ${colors.beige};
  padding: 0 ${spacing.small};
  margin: 0 -${spacing.small};
`;

export const WarningContent = styled.div`
  padding: ${spacing.xsmall} 0;
  border-top: 1px solid ${colors.compBeige_02};
`;
