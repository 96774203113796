import { type Location } from '@stenarecycling/customer-portal-types';
import { type Country } from '#types/location';
import { type ArticleGroupType, type ArticleItemType } from '#types/article';
import { type OrderItemType } from '#types/order';
import { articleExistsOnOrders } from '#utils/orderUtil';
import { HeadingType, HeadingStyleType } from '#components/Heading';
import ArticleItem from '../ArticleItem';
import ArticleGroupWrapper, { StyledHeading } from './styles';

export type ArticleGroupProps = {
  decrement: (id: string) => void;
  increment: (id: string) => void;
  handleOnSaveComment: (id: string, comment: string | null) => void;
  handleOnSaveWeight: (id: string, weight: number) => void;
  selectedLocation: Location | null;
  activeOrdersInSelectedLocation?: OrderItemType[];
  country?: Country;
} & ArticleGroupType;

const ArticleGroup = (props: ArticleGroupProps) => {
  const { name, articles, activeOrdersInSelectedLocation, country, ...rest } = props;

  const showDoubleBookingWarning = (article: ArticleItemType) =>
    articleExistsOnOrders(article.id, activeOrdersInSelectedLocation, country);

  return (
    <ArticleGroupWrapper>
      <StyledHeading styleType={HeadingStyleType.Heading5} headingType={HeadingType.H2} upperCase>
        <span translate="no">{name}</span>
      </StyledHeading>
      {articles.map((article) => (
        <ArticleItem
          data-testid="order-article"
          showDoubleBookingWarning={showDoubleBookingWarning(article)}
          {...article}
          {...rest}
          key={`${name}-${article.id}`}
        />
      ))}
    </ArticleGroupWrapper>
  );
};

export default ArticleGroup;
