import styled from '@emotion/styled';
import { css } from '@emotion/react';
import colors from '#assets/colors';
import fontSizes from '#assets/fontSizes';
import fontWeights from '#assets/fontWeights';
import { HeadingStyleType } from './index';

const fontStyle = (type: HeadingStyleType) => {
  switch (type) {
    case HeadingStyleType.Hero:
      return `
        font-size: ${fontSizes.hero};
        font-weight: ${fontWeights.bold};
        line-height: 72px;
        letter-spacing: 3px;
      `;
    case HeadingStyleType.Heading1:
      return `
        font-size: ${fontSizes.heading1};
        font-weight: ${fontWeights.bold};
        line-height: 56px;
        letter-spacing: 3px;
      `;
    case HeadingStyleType.Heading2:
      return `
        font-size: ${fontSizes.heading2};
        font-weight: ${fontWeights.medium};
        line-height: 48px;
        letter-spacing: 1px;
      `;
    case HeadingStyleType.Heading3:
      return `
        font-size: ${fontSizes.heading3};
        font-weight: ${fontWeights.bold};
        line-height: 40px;
        letter-spacing: 2px;
      `;
    case HeadingStyleType.Heading4:
      return `
        font-size: ${fontSizes.heading4};
        font-weight: ${fontWeights.normal};
        line-height: 30px;
        letter-spacing: 1px;
      `;
    case HeadingStyleType.Heading5:
      return `
        font-size: ${fontSizes.heading5};
        font-weight: ${fontWeights.normal};
        line-height: 28px;
        letter-spacing: 1px;
      `;
    case HeadingStyleType.Heading6:
      return `
        font-size: ${fontSizes.body18};
        font-weight: ${fontWeights.bold};
        line-height: 26px;
        letter-spacing: 1px;
      `;
    case HeadingStyleType.Heading7:
      return `
        font-size: ${fontSizes.body16};
        font-weight: ${fontWeights.bold};
        line-height: 24px;
        letter-spacing: 1px;
      `;
    default:
      return `
        font-size: ${fontSizes.heading1};
        font-weight: ${fontWeights.normal};
        line-height: 56px;
        letter-spacing: 1px;
      `;
  }
};

export type HProps = {
  fontColor?: string | null;
  center?: boolean | null;
  upperCase?: boolean | null;
  bold?: boolean | null;
  styleType: HeadingStyleType;
};

const overrideWithBold = (bold?: boolean | null) =>
  bold &&
  css`
    font-weight: ${fontWeights.bold};
  `;

const generalFontStyle = ({ fontColor, center, upperCase, bold }: HProps) => css`
  color: ${fontColor ?? colors.compCharcoal_00};
  text-align: ${center ? 'center' : 'initial'};
  text-transform: ${upperCase ? 'uppercase' : 'none'};
  ${overrideWithBold(bold)}
`;

export const StyledH1 = styled.h1<HProps>`
  ${({ styleType }) => fontStyle(styleType)};
  ${(props) => generalFontStyle(props)};
`;
export const StyledH2 = styled.h2<HProps>`
  ${({ styleType }) => fontStyle(styleType)};
  ${(props) => generalFontStyle(props)};
`;
export const StyledH3 = styled.h3<HProps>`
  ${({ styleType }) => fontStyle(styleType)};
  ${(props) => generalFontStyle(props)};
`;
export const StyledH4 = styled.h4<HProps>`
  ${({ styleType }) => fontStyle(styleType)};
  ${(props) => generalFontStyle(props)};
`;
export const StyledH5 = styled.h5<HProps>`
  ${({ styleType }) => fontStyle(styleType)};
  ${(props) => generalFontStyle(props)};
`;
export const StyledH6 = styled.h6<HProps>`
  ${({ styleType }) => fontStyle(styleType)};
  ${(props) => generalFontStyle(props)};
`;
