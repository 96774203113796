import styled from '@emotion/styled';
import { type SerializedStyles, css } from '@emotion/react';
import { breakpoint } from '../../../themes';
import { type GridItem, type GridProps } from '.';

export const CssGrid = styled.div<GridProps>`
  display: grid;
  ${({ gap }) => getColumnGap({ gap })};
  ${({ columns }) => getColumns({ columns })};
  ${({ rowGap, gap }) => getRowGap({ rowGap: rowGap ?? gap })};
`;

export const CssGridItem = styled.div<GridItem>`
  ${({ gridColumn }) => getGridColumn({ gridColumn })};
  ${({ gridRow }) => getGridRow({ gridRow })};
`;

const getColumns = ({ columns = [2, 2, 4, 12] }: Pick<GridProps, 'columns'>): SerializedStyles => {
  if (typeof columns === 'string') {
    return css`
      grid-template-columns: ${columns};
    `;
  }

  if (typeof columns === 'number') {
    return css`
      grid-template-columns: repeat(${columns}, 1fr);
    `;
  }

  return css`
    ${columns[3] &&
    `
      grid-template-columns: repeat(${columns[3]}, 1fr);
    `}

    ${columns[2] &&
    `@media (max-width: ${breakpoint.large}px) {
      grid-template-columns: repeat(${columns[2]}, 1fr);
    }`}

    ${columns[1] &&
    `@media (max-width: ${breakpoint.medium}px) {
      grid-template-columns: repeat(${columns[1]}, 1fr);
    }`}

    ${columns[0] &&
    `@media (max-width: ${breakpoint.small}px) {
      grid-template-columns: repeat(${columns[0]}, 1fr);
    }`}
  `;
};

const getGridColumn = ({ gridColumn }: Pick<GridItem, 'gridColumn'>): SerializedStyles => {
  if (!gridColumn) {
    return css`
      grid-column: auto;
    `;
  }

  if (typeof gridColumn === 'string') {
    return css`
      grid-column: ${gridColumn};
    `;
  }

  if (typeof gridColumn === 'number') {
    return css`
      grid-column: ${gridColumn};
    `;
  }

  return css`
    ${gridColumn[3] && `grid-column: ${gridColumn[3]};`}

    ${gridColumn[2] &&
    `@media screen and (max-width: ${breakpoint.large}px) {
      grid-column: ${gridColumn[2]};
    }`}

  ${gridColumn[1] &&
    `@media screen and (max-width: ${breakpoint.medium}px) {
      grid-column: ${gridColumn[1]};
    }`}

  ${gridColumn[0] &&
    `@media screen and (max-width: ${breakpoint.small}px) {
      grid-column: ${gridColumn[0]};
    }`}
  `;
};

const getGridRow = ({ gridRow }: Pick<GridItem, 'gridRow'>): SerializedStyles => {
  if (!gridRow) {
    return css`
      grid-row: auto;
    `;
  }

  if (typeof gridRow === 'string') {
    return css`
      grid-row: ${gridRow};
    `;
  }

  if (typeof gridRow === 'number') {
    return css`
      grid-row: ${gridRow};
    `;
  }

  return css`
    ${gridRow[3] && `grid-row: ${gridRow[3]};`}

    ${gridRow[2] &&
    `@media screen and (max-width: ${breakpoint.large}px) {
      grid-row: ${gridRow[2]};
    }`}

    ${gridRow[1] &&
    `@media screen and (max-width: ${breakpoint.medium}px) {
      grid-row: ${gridRow[1]};
    }`}

    ${gridRow[0] &&
    `@media screen and (max-width: ${breakpoint.small}px) {
      grid-row: ${gridRow[0]};
    }`}
  `;
};

const getColumnGap = ({ gap }: Pick<GridProps, 'gap'>): SerializedStyles => {
  if (!gap) {
    return css`
      column-gap: 0;
    `;
  }

  if (typeof gap === 'string') {
    return css`
      column-gap: ${gap};
    `;
  }

  if (typeof gap === 'number') {
    return css`
      column-gap: ${gap}px;
    `;
  }

  return css`
    ${gap[3] && `column-gap: ${gap[3]}px;`}

    ${gap[2] &&
    `@media screen and (max-width: ${breakpoint.large}px) {
      column-gap: ${gap[2]}px;
    }`}

    ${gap[1] &&
    `@media screen and (max-width: ${breakpoint.medium}px) {
      column-gap: ${gap[1]}px;
    }`}

    ${gap[0] &&
    `@media screen and (max-width: ${breakpoint.small}px) {
      column-gap: ${gap[0]}px;
    }`}
  `;
};

const getRowGap = ({ rowGap }: Pick<GridProps, 'rowGap'>): SerializedStyles => {
  if (!rowGap) {
    return css`
      row-gap: 0;
    `;
  }

  if (typeof rowGap === 'string') {
    return css`
      row-gap: ${rowGap};
    `;
  }

  if (typeof rowGap === 'number') {
    return css`
      row-gap: ${rowGap}px;
    `;
  }

  return css`
    ${rowGap[3] && `row-gap: ${rowGap[3]}px;`}

    ${rowGap[2] &&
    `@media screen and (max-width: ${breakpoint.large}px) {
      row-gap: ${rowGap[2]}px;
    }`}

    ${rowGap[1] &&
    `@media screen and (max-width: ${breakpoint.medium}px) {
      row-gap: ${rowGap[1]}px;
    }`}

    ${rowGap[0] &&
    `@media screen and (max-width: ${breakpoint.small}px) {
      row-gap: ${rowGap[0]}px;
    }`}
  `;
};
