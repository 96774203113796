import styled from '@emotion/styled';
import spacing from '#assets/spacing';
import colors from '#assets/colors';
import Heading from '#components/Heading';
import Paragraph from '#components/Paragraph';

const OrderLocationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: ${spacing.xsmall} 0;
  border-bottom: 1px solid ${colors.primGrey_03};
`;

export const TextWrapper = styled.div`
  flex-basis: 90%;
  margin-left: ${spacing.xsmall};
`;

export const Name = styled(Heading)`
  color: ${colors.compCharcoal_00};
`;

export const Description = styled(Paragraph)`
  color: ${colors.primGrey_00};
`;

export const Address = styled(Paragraph)`
  color: ${colors.primGrey_00};
`;

export default OrderLocationWrapper;
