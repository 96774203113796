import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { type Location } from '@stenarecycling/customer-portal-types';
import { routePaths } from '#routes/routePaths';
import { type OrderItemType } from '#types/order';
import { ParagraphStyleType } from '#components/Paragraph';
import OrderLocation from '../OrderLocation';
import OrderItem from '../OrderItem';
import OrderGroupWrapper, { SubGroupWrapper, SubGroupLabel } from './styles';

export type OrderGroupProps = {
  location: Location;
  orderItems: OrderItemType[];
};

const OrderGroup = ({ location, orderItems }: OrderGroupProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const showOrderDetails = (locationId: string, orderId: string, articleId: string) => {
    navigate(`${routePaths.USER_ORDERS}/${locationId}__${orderId}__${articleId}`, {
      state: { from: routePaths.USER_ORDERS },
    });
  };

  const emptyList: OrderItemType[] = [];

  const allOrderItems = orderItems.reduce(
    (result, orderItem) => {
      if (Number(orderItem.status) < 4) {
        result['activeOrderItems'] = [...result['activeOrderItems'], orderItem];
      } else if (Number(orderItem.status) === 4) {
        result['completedOrderItems'] = [...result['completedOrderItems'], orderItem];
      } else if (Number(orderItem.status) === 5) {
        result['rejectedOrderItems'] = [...result['rejectedOrderItems'], orderItem];
      }

      return result;
    },
    { activeOrderItems: emptyList, completedOrderItems: emptyList, rejectedOrderItems: emptyList },
  );

  const { activeOrderItems, completedOrderItems, rejectedOrderItems } = allOrderItems;

  return (
    <OrderGroupWrapper>
      <OrderLocation {...{ location }} />

      {activeOrderItems.length > 0 && (
        <SubGroupWrapper>
          <SubGroupLabel styleType={ParagraphStyleType.body14} upperCase>
            {t('userOrder.active.order')}
          </SubGroupLabel>
          {activeOrderItems.map((orderItem) => (
            <OrderItem
              orderItem={orderItem}
              showOrderDetails={() => {
                showOrderDetails(location.dwKey, orderItem.id, orderItem.articleId);
              }}
              key={`${location.id}_${orderItem.id}`}
            />
          ))}
        </SubGroupWrapper>
      )}

      {completedOrderItems.length > 0 && (
        <SubGroupWrapper>
          <SubGroupLabel styleType={ParagraphStyleType.body14} upperCase>
            {t('userOrder.recently.completed')}
          </SubGroupLabel>
          {completedOrderItems.map((orderItem) => (
            <OrderItem
              orderItem={orderItem}
              showOrderDetails={() => {
                showOrderDetails(location.dwKey, orderItem.id, orderItem.articleId);
              }}
              key={`${location.id}_${orderItem.id}`}
            />
          ))}
        </SubGroupWrapper>
      )}

      {rejectedOrderItems.length > 0 && (
        <SubGroupWrapper>
          <SubGroupLabel styleType={ParagraphStyleType.body14} upperCase>
            {t('userOrder.rejected.order')}
          </SubGroupLabel>
          {rejectedOrderItems.map((orderItem) => (
            <OrderItem
              orderItem={orderItem}
              showOrderDetails={() => {
                showOrderDetails(location.dwKey, orderItem.id, orderItem.articleId);
              }}
              key={`${location.id}_${orderItem.id}`}
            />
          ))}
        </SubGroupWrapper>
      )}
    </OrderGroupWrapper>
  );
};

export default OrderGroup;
