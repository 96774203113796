import styled from '@emotion/styled';
import { colors, typography } from '../../../../../../../../themes';

export const StyledLocationGroup = styled.li<{ nestedIndex: number }>`
  ${typography.body.large};
  display: flex;
  flex-flow: column;
  ${({ nestedIndex }) =>
    nestedIndex === 0 && `border-bottom: 1px solid ${colors.monochrome.grey20};`}
`;

export const StyledDescription = styled.div`
  ${typography.body.medium};
  color: ${colors.primary.grey};
`;

export const StyledChildCount = styled.div`
  ${typography.body.medium};
  color: ${colors.primary.grey};
`;

export const StyledChildWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-flow: row;
  gap: 4px;
`;
