import { type ReactNode, type RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useEventListener } from '#utils/hooks/useEventListener';

type SequcenceEvent = any;

export type EasterEgg = {
  eventTarget?: EventTarget;
  eventType: string;
  callback: (sequence: SequcenceEvent[]) => boolean;
};

const EasterEggComponent = (
  easterEgg: EasterEgg,
  EasterEggElement: RefObject<HTMLHeadingElement>,
) => {
  const [sequence, setSequence] = useState<SequcenceEvent[]>([]);
  const { eventType, callback } = easterEgg;
  let { eventTarget } = easterEgg;

  if (!eventTarget && EasterEggElement.current) {
    eventTarget = EasterEggElement.current as EventTarget;
  }

  const eventHandler = useCallback(
    (event: Event) => {
      setSequence([...sequence, ...[event as SequcenceEvent]]);
    },
    [sequence],
  );

  useEffect(() => {
    if (sequence.length > 0 && callback && callback(sequence)) {
      setSequence([]);
    }
  }, [callback, sequence]);

  useEventListener(eventType, eventHandler, eventTarget);
};

export const EasterEgg = ({
  children,
  easterEggs,
}: {
  children: ReactNode;
  easterEggs: EasterEgg[];
}) => {
  const EasterEggElement = useRef<HTMLHeadingElement>(null);

  easterEggs.forEach((easterEgg) => {
    EasterEggComponent(easterEgg, EasterEggElement);
  });

  return <div ref={EasterEggElement}>{children}</div>;
};
