import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { stenaRecycling, ThemeProvider } from '@stenametall/component-library';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'core/AuthProvider';
import { GovernanceProvider } from 'core/GovernanceProvider';
import { AllowUnauthenticatedContentProvider } from 'core/lib/Content/ContentProvider';
import store from '#state/store';
import App from '#App';
import './index.css';
import newApiFlag from '#components/EasterEgg/easterEggs/newApiFlag';
import { EasterEgg } from '#components/EasterEgg';
import { resources } from '#i18n/i18n';
import { OPV2Modal } from '#components/OPV2Prompt/OPV2Modal';

const rootElement = document.getElementById('root');

if (!rootElement) throw Error('Failed to find the root element');

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={stenaRecycling}>
          <AuthProvider cacheLocation="localstorage">
            <GovernanceProvider sentryDsn="https://aaadac327cae4aa78546bf6219f29cd7@o481031.ingest.sentry.io/6058318">
              <AllowUnauthenticatedContentProvider
                useBackendContentProvider={false}
                applicationName="order-pickup-pwa"
                resources={resources}
                simpleLoader={true}
              >
                <EasterEgg easterEggs={[newApiFlag]}>
                  <OPV2Modal />
                  <App />
                </EasterEgg>
              </AllowUnauthenticatedContentProvider>
            </GovernanceProvider>
          </AuthProvider>
        </ThemeProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
);
