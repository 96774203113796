import { type LegacyRef, forwardRef } from 'react';
import { Calendar } from '../../../../../Icons/Calendar';
import { InputWrapper, IconWrapper, StyledTextfield } from '../../styles';
import { IconButton } from '../../../../IconButton';

const CustomInputDatepicker = forwardRef(
  (
    props: {
      value?: string;
      editable?: boolean;
      onClick?: () => void;
      placeholderText?: string;
      disabled?: boolean;
    },
    ref: LegacyRef<HTMLDivElement>,
  ) => {
    return (
      <InputWrapper onClick={props.onClick} ref={ref}>
        <StyledTextfield
          value={props.value}
          readOnly
          disabled={props.disabled}
          placeholder={props.placeholderText}
        />
        <IconWrapper disabled={props.disabled}>
          <IconButton
            data-testid="toggle-datepicker"
            onClick={props.onClick}
            variant="secondary"
            disabled={props.disabled}
          >
            <Calendar />
          </IconButton>
        </IconWrapper>
      </InputWrapper>
    );
  },
);

CustomInputDatepicker.displayName = 'CustomInputDatepicker';

export default CustomInputDatepicker;
