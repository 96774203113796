import styled from '@emotion/styled';
import { css } from '@emotion/react';
import spacing from '#assets/spacing';
import colors from '#assets/colors';

const RadioButtonListWrapper = styled.div`
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: ${spacing.small};
  margin: 0 -${spacing.small};
`;

export const RadioButtonSection = styled.label<{
  buttonsRight?: boolean;
  checked: boolean;
}>`
  display: flex;
  align-items: center;
  padding: ${spacing.small} 0 ${spacing.small} ${spacing.small};
  border-bottom: 1px solid ${colors.primGrey_03};
  &:first-of-type {
    border-top: 1px solid ${colors.primGrey_03};
  }
  ${({ buttonsRight, checked }) =>
    buttonsRight &&
    css`
      flex-direction: row-reverse;
      justify-content: space-between;
      background-color: ${checked ? colors.compBeige_03 : 'initial'};
      padding-right: ${spacing.xxsmall};
    `}
`;

export const RadioTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default RadioButtonListWrapper;
