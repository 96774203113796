import { useTranslation } from 'react-i18next';
import { type Location } from '@stenarecycling/customer-portal-types';
import { routePaths } from '#routes/routePaths';
import {
  selectLocations,
  selectSelectedLocation,
  updateSelectedLocation,
} from '#state/locationsSlice';
import { Country } from '#types/location';
import { useApi } from '#utils/hooks/useApi';
import { useDispatch, useSelector } from '#utils/hooks/redux';
import Page from '#components/Page';
import BackButton from '#components/BackButton';
import LocationComponent from '../../PlaceOrder/Location';
import BranchContactInfo from './BranchContactInfo';
import YourBranchWrapper from './styles';

const YourBranch = () => {
  const { t } = useTranslation();

  const locations = useSelector(selectLocations);
  const selectedLocation = useSelector(selectSelectedLocation);
  const dispatch = useDispatch();
  const { fetchArticles } = useApi();

  const handleUpdateSelectedLocation = (locationId: string) => {
    dispatch(updateSelectedLocation(locationId));

    const newLocation = locations.find((location) => location.id === locationId);

    if (newLocation) {
      fetchArticles({
        partnerId: newLocation.dwKey,
        pickupAddressId: newLocation.pickupAddressId,
      });
    }
  };

  const swedishLocations: Location[] = locations.filter(
    (location) => location.country === Country.SWEDEN,
  );

  return (
    <Page
      title={t('userProfile.branch.title')}
      trackedTitle={t('userProfile.branch.title', { lng: 'en' })}
      leftActionButton={
        <BackButton to={routePaths.SETTINGS} state={{ from: routePaths.YOUR_BRANCH }} />
      }
    >
      <YourBranchWrapper>
        <LocationComponent
          updateSelectedLocation={handleUpdateSelectedLocation}
          initialLocation={selectedLocation}
          locations={swedishLocations}
          buttonsRight={true}
        />
        <BranchContactInfo pickupPlaceId={selectedLocation?.id} />
      </YourBranchWrapper>
    </Page>
  );
};

export default YourBranch;
