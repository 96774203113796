import { useTranslation } from 'react-i18next';
import { Button, type ButtonProps } from '@stenametall/component-library';
import { useAuth0 } from '@auth0/auth0-react';
import { useDialog } from '#utils/hooks/useDialog';
import colors from '#assets/colors';
import backgroundImg from '#assets/images/startpage-img.jpg';
import Page from '#components/Page';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import { HeadingType, HeadingStyleType } from '#components/Heading';
import NoAccount from './NoAccount';
import {
  IntroWrapper,
  IntroImage,
  IntroTitle,
  BulletPoints,
  Nav,
  TextWrapper,
  NoAccountLink,
  NoAccountParagraph,
} from './styles';

const Introduction = () => {
  const { t } = useTranslation();
  const { isOpen, open, close } = useDialog();
  const { loginWithRedirect } = useAuth0();

  //Fix for bad types in used lib
  const buttonProps = {} as ButtonProps & {
    onPointerLeaveCapture: () => void;
    onPointerEnterCapture: () => void;
    placeholder: string;
  };

  return (
    <Page showHeaderLogo>
      <IntroWrapper>
        <IntroImage backgroundImageUrl={backgroundImg} />
        <TextWrapper>
          <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.primGrey_00} upperCase>
            {t('introduction.byline')}
          </Paragraph>
          <IntroTitle
            headingType={HeadingType.H2}
            styleType={HeadingStyleType.Heading4}
            upperCase
            bold
          >
            {t('introduction.title')}
          </IntroTitle>
          <BulletPoints>
            <ul>
              <li>{t('introduction.bulletPoint.1')}</li>
              <li>{t('introduction.bulletPoint.2')}</li>
              <li>{t('introduction.bulletPoint.3')}</li>
            </ul>
          </BulletPoints>
        </TextWrapper>
        <Nav>
          <Button onClick={() => loginWithRedirect()} {...buttonProps}>
            {t('introduction.cta.text')}
          </Button>
        </Nav>
        <NoAccountParagraph styleType={ParagraphStyleType.body16}>
          <span>{t('introduction.noAccount.text')}</span>{' '}
          <NoAccountLink onClick={open}>{t('introduction.noAccount.link')}</NoAccountLink>
        </NoAccountParagraph>
      </IntroWrapper>
      <NoAccount isOpen={isOpen} onClose={close} />
    </Page>
  );
};

export default Introduction;
