import {
  sv as svAuth,
  en as enAuth,
  no as noAuth,
  da as daAuth,
  fi as fiAuth,
} from 'core/lib/assets/texts';

import en from '../assets/texts/en.json';
import sv from '../assets/texts/sv.json';
import da from '../assets/texts/da.json';
import fi from '../assets/texts/fi.json';
import no from '../assets/texts/no.json';

export const resources = {
  en: {
    translation: { ...enAuth, ...en },
  },
  sv: {
    translation: { ...svAuth, ...sv },
  },
  da: {
    translation: { ...daAuth, ...da },
  },
  fi: {
    translation: { ...fiAuth, ...fi },
  },
  no: {
    translation: { ...noAuth, ...no },
  },
};
