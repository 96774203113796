import styled from '@emotion/styled';
import colors from '#assets/colors';
import spacing from '#assets/spacing';

const MenuWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 4;
  height: ${spacing.bottomMenuHeight};
  width: 100%;
  max-width: 1380px;
  margin: auto;
  border-top: 1px solid ${colors.primGrey_03};
  background-color: ${colors.white};

  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${spacing.xsmall};
  padding-bottom: ${spacing.xsmall};

  // Adds extra padding for ex. iphone home indicator
  //max-padding of spacing.small and min-padding spacing.xsmall
  padding-bottom: max(min(env(safe-area-inset-bottom), ${spacing.small}), ${spacing.xsmall});
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
`;
export default MenuWrapper;
