import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import colors from '#assets/colors';
import spacing from '#assets/spacing';
import Heading from '#components/Heading';
import Paragraph from '#components/Paragraph';
import ATag from '#components/ATag';
import fontSizes from '#assets/fontSizes';
import fontWeights from '#assets/fontWeights';

export const FeedbackAndHelpWrapper = styled.div`
  background-color: ${colors.white};
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Heading)`
  padding-bottom: ${spacing.xsmall};
`;

export const StyledParagraph = styled(Paragraph)`
  padding-bottom: ${spacing.small};
`;

export const TopContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing.mediumLow} ${spacing.small};
`;

export const TopParagraph = styled(Paragraph)`
  margin-bottom: ${spacing.xsmall};
`;
export const TopButtonWrapper = styled.div`
  display: flex;
`;
export const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacing.mediumLow} ${spacing.small};
  background-color: ${colors.beige};
  border-top: 1px solid ${colors.primGrey_03};
  border-bottom: 1px solid ${colors.primGrey_03};
  flex-grow: 1;
`;

export const LikedButton = styled.button`
  border-style: none;
  border: 2px solid ${colors.primGrey_03};
  border-radius: 8px;
  opacity: 100%;
  padding: 32px;
  background-color: ${colors.primGrey_03};
`;

export const StyledATag = styled(ATag)`
  font-size: ${fontSizes.body16};
  line-height: 24px;
  font-weight: ${fontWeights.normal};
`;

export const FeedbackContainer = styled.div`
  padding-top: ${spacing.small};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  max-width: 325px;
`;

export const SubmitButton = styled.button`
  border-style: none;
  cursor: pointer;
  display: block;
  color: ${colors.white};
  background-color: ${colors.primGreen_00};
  padding: ${spacing.xsmall} ${spacing.small};
  margin-bottom: ${spacing.small};
  font-size: ${fontSizes.body16};
  line-height: 24px;
  font-weight: ${fontWeights.bold};
  letter-spacing: 1.5px;
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  text-align: center;
  width: 100%;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: ${spacing.xxsmall};
  font-size: ${fontSizes.body18};
  line-height: 26px;
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.5px;
  color: ${colors.black};
  margin-bottom: ${spacing.small};
  font-family: StenaSans;
`;

export const TextAreaParagraph = styled(Paragraph)`
  align-self: flex-start;
  margin-bottom: ${spacing.tiny};
`;

export const FeedbackSentContainer = styled.div`
  padding-top: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ClappingEmojiContainer = styled.div`
  font-size: 40px;
  margin-bottom: ${spacing.xsmall};
`;

export const ThanksBoldParagraph = styled(Paragraph)`
  margin-bottom: ${spacing.xsmall};
`;

export const ThanksTextParagraph = styled(Paragraph)`
  text-align: center;
`;

export const PrivacyLink = styled(Link)`
  color: ${colors.primGrey_00};
`;
