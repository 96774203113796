import styled from '@emotion/styled';
import colors from '#assets/colors';
import fontSizes from '#assets/fontSizes';
import fontWeights from '#assets/fontWeights';

export const StyledATag = styled.a`
  font-size: ${fontSizes.body14};
  font-weight: ${fontWeights.normal};
  line-height: 22px;
  letter-spacing: 0.5px;
  color: ${colors.compBlue_00};
  text-decoration: underline;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
