import { useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { useProfileV2 } from '#lib/Profile';
import { checkIfInternalUser } from '../utils/isInternalUser';

const useHasCo2ImpactAccess = () => {
  const co2ImpactInternalAccess = useFlag('co2-impact-internal-access');
  const { profile } = useProfileV2();
  const hasCo2ImpactAccess = useMemo(() => {
    if (!profile) {
      return false;
    }
    const isInternalUser = checkIfInternalUser(profile.email);

    if (co2ImpactInternalAccess) {
      if (isInternalUser) {
        return true;
      }
    } else {
      if (profile.permissions?.superUser) {
        return true;
      }
    }

    return !!profile.permissions?.businessPartners.some((bp) =>
      bp.services.some(
        (service) => service.name === 'services.follow.up.name' && service.co2Impact,
      ),
    );
  }, [co2ImpactInternalAccess, profile]);

  return hasCo2ImpactAccess;
};

export default useHasCo2ImpactAccess;
