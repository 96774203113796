import { useAuth0 } from '@auth0/auth0-react';
import { useTransactionId } from 'core/lib/hooks/useTransactionId';
import { useProfileV2 } from 'core/lib/Profile';
import { useDispatch } from '#utils/hooks/redux';
import {
  sendOrder as _sendOrder,
  fetchOrders as _fetchOrders,
  fetchAllOrders as _fetchAllOrders,
} from '#state/ordersSlice';
import { fetchLocations as _fetchLocations } from '#state/locationsSlice';
import { fetchArticles as _fetchArticles } from '#state/articlesSlice';
import { type SendOrderType } from '#types/order';

export const useApi = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const transactionId = useTransactionId();
  const { profile } = useProfileV2();

  const sendOrder = async (order: SendOrderType) => {
    const token = await getAccessTokenSilently();

    dispatch(_sendOrder({ token, transactionId, ...order }));
  };

  const fetchOrders = async (partnerId: string) => {
    const token = await getAccessTokenSilently();

    dispatch(_fetchOrders({ token, transactionId, partnerId, userEmail: profile?.email ?? '' }));
  };

  const fetchAllOrders = async (args: { partnerId: string; userEmail?: string }) => {
    const token = await getAccessTokenSilently();

    dispatch(
      _fetchAllOrders({
        token,
        transactionId,
        partnerId: args.partnerId,
        userEmail: args.userEmail,
      }),
    );
  };

  const fetchLocations = async () => {
    const token = await getAccessTokenSilently();

    if (!profile || !profile.permissions) {
      return;
    }
    dispatch(_fetchLocations({ token, transactionId, permissions: profile.permissions }));
  };

  const fetchArticles = async ({
    partnerId,
    pickupAddressId,
  }: {
    partnerId: string;
    pickupAddressId?: string;
  }) => {
    const token = await getAccessTokenSilently();

    dispatch(_fetchArticles({ token, transactionId, partnerId, pickupAddressId }));
  };

  return { sendOrder, fetchOrders, fetchAllOrders, fetchLocations, fetchArticles };
};
