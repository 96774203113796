import { useTranslation } from 'react-i18next';
import { ParagraphStyleType } from '#components/Paragraph';
import colors from '#assets/colors';
import { type ArticleItemType } from '#types/article';
import ConfirmationListItemWrapper, {
  ConfirmationListComment,
  ConfirmationListItemLabel,
  ConfirmationListItemValue,
} from './styles';

type ConfirmationListItemProps = {
  article: ArticleItemType;
};

const ConfirmationListItem = ({ article, ...rest }: ConfirmationListItemProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationListItemWrapper {...rest}>
      <ConfirmationListItemLabel styleType={ParagraphStyleType.body16} fontColor={colors.black}>
        {article.title}
      </ConfirmationListItemLabel>
      <ConfirmationListItemValue styleType={ParagraphStyleType.body16} fontColor={colors.black}>
        {article.numberOfItems}
      </ConfirmationListItemValue>
      <ConfirmationListItemLabel styleType={ParagraphStyleType.body14}>
        {article.description}
      </ConfirmationListItemLabel>
      {article.weight && article.weight !== 0 && (
        <ConfirmationListItemLabel styleType={ParagraphStyleType.body14}>
          {article.weight} kg
        </ConfirmationListItemLabel>
      )}
      <ConfirmationListComment styleType={ParagraphStyleType.body14}>
        {t('confirmation.success.article.comment.title')} {article.comment}
      </ConfirmationListComment>
    </ConfirmationListItemWrapper>
  );
};

export default ConfirmationListItem;
