import styled from '@emotion/styled';
import spacing from '#assets/spacing';
import colors from '#assets/colors';
import Paragraph from '#components/Paragraph';

const ConfirmationListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 15fr 1fr;
  padding: ${spacing.xsmall} ${spacing.xsmall};
  border: 1px solid ${colors.compBeige_03};
  margin-bottom: ${spacing.xxsmall};
`;

export const ConfirmationListItemLabel = styled(Paragraph)`
  grid-column-start: 1;
`;

export const ConfirmationListItemValue = styled(Paragraph)`
  grid-column-start: 2;
`;

export const ConfirmationListComment = styled(Paragraph)`
  grid-column-start: span 2;
  margin-top: ${spacing.xxsmall};
`;

export default ConfirmationListItemWrapper;
