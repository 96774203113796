import styled from '@emotion/styled';
import colors from '#assets/colors';
import spacing from '#assets/spacing';
import Heading from '#components/Heading';
import Paragraph from '#components/Paragraph';

export const OrderDetailsWrapper = styled.div`
  height: 100%;
  background-color: ${colors.white};
`;

export const BlockWrapper = styled.div`
  border-top: 1px solid ${colors.primGrey_03};
  padding: ${spacing.mediumLow} ${spacing.small} ${spacing.xxsmall};
`;

export const BlockTitle = styled(Heading)`
  margin-bottom: ${spacing.small};
`;

export const Row = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &:first-of-type {
    margin-right: ${spacing.mediumLow};
  }
  padding-bottom: ${spacing.small};
`;

export const Label = styled(Paragraph)`
  margin-bottom: ${spacing.tiny};
`;

export const Value = styled(Paragraph)``;
