/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import styled from '@emotion/styled';
import { type ReactNode } from 'react';
import { type Spacing, breakpoint, colors, spacing, typography } from '../../../themes';

type HeadingProps = {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  variant: 'small' | 'sCaps' | 'medium' | 'large' | 'xLarge' | 'mega';
  align?: 'left' | 'center' | 'right';
  mt?: string | Spacing;
  mb?: string | Spacing;
  color?: string;
  children?: ReactNode;
  testId?: string;
};

export const Heading = ({ as, variant, mt, mb, children, color, align, ...rest }: HeadingProps) => {
  const isSpacingSyntax = (value: string | Spacing): value is Spacing => {
    return typeof value === 'string' && value in spacing;
  };

  if (mb && isSpacingSyntax(mb)) {
    mb = spacing[mb];
  }

  if (mt && isSpacingSyntax(mt)) {
    mt = spacing[mt];
  }

  const StyledHeading = styled[as]`
    color: ${color ?? colors.base.black};
    ${align && `text-align: ${align};`}
    ${typography.heading.desktop[variant]}
    @media (max-width: ${breakpoint.medium}px) {
      ${typography.heading.mobile[variant]}
    }

    margin-top: ${mt ?? '0px'};
    margin-bottom: ${mb ?? '0px'};
  `;

  return <StyledHeading {...rest}>{children}</StyledHeading>;
};
