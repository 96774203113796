import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import colors from '#assets/colors';

export const CircleMotion = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${colors.white};
`;
