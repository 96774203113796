import { useTranslation } from 'react-i18next';
import { type OrderItemType } from '#types/order';
import colors from '#assets/colors';
import { HeadingStyleType, HeadingType } from '#components/Heading';
import { ParagraphStyleType } from '#components/Paragraph';
import { BlockWrapper, BlockTitle, Column, Label, Value } from '../styles';
import { ItemDescription } from './styles';

export type CommentBlockProps = {
  order?: OrderItemType | null;
};

const CommentBlock = ({ order }: CommentBlockProps) => {
  const { t } = useTranslation();

  return (
    <BlockWrapper>
      <BlockTitle headingType={HeadingType.H2} styleType={HeadingStyleType.Heading7} upperCase>
        {t('orderDetails.comment.title')}
      </BlockTitle>
      <ItemDescription>
        <Column>
          <Label styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_00} upperCase>
            {t('orderDetails.comment.customerComment')}
          </Label>
          <Value styleType={ParagraphStyleType.body16}>{order?.customerComment || '-'}</Value>
        </Column>
      </ItemDescription>
      <Column>
        <Label styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_00} upperCase>
          {t('orderDetails.comment.comment')}
        </Label>
        <Value styleType={ParagraphStyleType.body16}>{order?.comment || '-'}</Value>
      </Column>
    </BlockWrapper>
  );
};

export default CommentBlock;
