import styled from '@emotion/styled';

export const AppContainerWrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'transparent'};
`;

export const InnerAppContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
