import axios, { type AxiosRequestConfig } from 'axios';
import { environment } from '#helper/environment';
import { type PayloadCreator } from '#types/generic';
import { type ArticleItemType } from '#types/article';
import { createArticlePerContainer } from '#utils/articleUtil';

export const getArticles = async (
  {
    token,
    transactionId,
    partnerId,
    pickupAddressId = '',
  }: { token: string; transactionId: string; partnerId: string; pickupAddressId?: string },
  { signal, rejectWithValue }: PayloadCreator,
) => {
  const source = axios.CancelToken.source();

  signal.addEventListener('abort', () => {
    source.cancel();
  });

  const requestConfig: AxiosRequestConfig = {
    cancelToken: source.token,
    params: {
      partnerId,
      pickupAddressId,
    },
  };

  if (token) {
    requestConfig.headers = { authorization: `Bearer ${token}`, 'X-Transaction-ID': transactionId };
  }

  return axios
    .get<ArticleItemType[]>(
      `${environment.newApi.url}${environment.newApi.path.articles}`,
      requestConfig,
    )
    .then((response) => response.data)
    .then((articles) => {
      return articles.flatMap((article) => createArticlePerContainer(article));
    })
    .catch((error) => {
      throw rejectWithValue(error?.response?.data as Error);
    });
};
