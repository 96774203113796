import { createSlice, createAsyncThunk, type PayloadAction } from '@reduxjs/toolkit';
import { type Location, type StenaPermissions } from '@stenarecycling/customer-portal-types';
import { type RootState } from '#state/store';
import { getLocations } from '#api/partners';

export const fetchLocations = createAsyncThunk(
  'locations/fetchLocations',
  async (
    props: { token: string; transactionId: string; permissions: StenaPermissions },
    payloadCreator,
  ) => {
    const locations = (await getLocations(
      props.token,
      props.transactionId,
      props.permissions,
      payloadCreator,
    )) as Location[];

    return locations;
  },
);

type LocationsState = {
  locations: Location[];
  selectedLocation: Location | null;
  loading: boolean;
  errorLoading: string | null;
};

export const initialLocationsState: LocationsState = {
  locations: [],
  selectedLocation: null,
  loading: false,
  errorLoading: null,
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState: initialLocationsState,
  reducers: {
    updateSelectedLocation: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const newLocation = state.locations.find((location) => location.dwKey === id);

      if (newLocation) {
        state.selectedLocation = newLocation;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLocations.fulfilled, (state, { payload: locations }) => {
        state.locations = locations;
        state.loading = false;
        state.errorLoading = null;
      })
      .addCase(fetchLocations.rejected, (state, { payload: errorMessage }) => {
        state.locations = [];
        state.loading = false;
        state.errorLoading = errorMessage as string;
      });
  },
});

export const { updateSelectedLocation } = locationsSlice.actions;

export const selectLocations = (state: RootState) => state.locations.locations;
export const selectSelectedLocation = (state: RootState) => state.locations.selectedLocation;
export const selectLoadingLocations = (state: RootState) => state.locations.loading;

export default locationsSlice.reducer;
