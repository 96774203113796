import { type SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { Button, ButtonColor, SearchInput } from '@stenametall/component-library';
import { type Location } from '@stenarecycling/customer-portal-types';
import Dialog from '#components/Dialog';
import { HeadingType, HeadingStyleType } from '#components/Heading';
import { ParagraphStyleType } from '#components/Paragraph';
import RadioButtonList from '#components/RadioButtonList';
import { ContentWrapper, InnerContent, Title, InfoText, ListTitle, InputWrapper } from './styles';

export type LocationDialogProps = {
  locations: Location[];
  onClose: () => void;
  handleOnLocationChange: (e: SyntheticEvent) => void;
  selectedLocationId: string;
  initialLocationId?: string;
  handleOnSave: () => void;
  isOpen: boolean;
  buttonsRight?: boolean;
};

const LocationDialog = (props: LocationDialogProps) => {
  const {
    locations,
    isOpen,
    onClose,
    handleOnSave,
    initialLocationId,
    selectedLocationId,
    handleOnLocationChange,
    buttonsRight,
  } = props;

  const { t } = useTranslation();

  const isSelectedSameAsInitialLocation = () => {
    return selectedLocationId === initialLocationId;
  };

  const locationLimitSearch = 10;
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchList, setSearchList] = useState<Location[]>();
  const [searchString, setSearchString] = useState('');

  const onFilter = (searchTerm: string) => {
    setSearchString(searchTerm);
    if (searchString.length < 3) {
      return;
    }
    setSearchLoading(true);
    const trimmedSearchString = searchTerm.trim().toLowerCase();

    onSearch(trimmedSearchString);
  };

  const onSearch = debounce((searchTerm: string) => {
    setSearchLoading(true);
    const filteredLocations = locations.filter((item) => {
      const { name, description, id, address } = item;

      return (
        name.toLowerCase().includes(searchTerm) ||
        (description && description.toLowerCase().includes(searchTerm)) ||
        id.toLowerCase().includes(searchTerm) ||
        address.street.toLowerCase().includes(searchTerm) ||
        address.city.toLowerCase().includes(searchTerm)
      );
    });

    setSearchList(filteredLocations);
    setSearchLoading(false);
  }, 300);

  useEffect(() => {
    if (searchString.length < 3) {
      return;
    }
    onFilter(searchString);
  }, [searchString]);

  useEffect(() => {
    setSearchString('');
  }, [isOpen]);

  //Fix for bad types in used lib
  const typeFix = {} as {
    onPointerLeaveCapture: () => void;
    onPointerEnterCapture: () => void;
    placeholder: string;
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} fullscreen>
      <ContentWrapper>
        <InnerContent>
          <Title headingType={HeadingType.H2} styleType={HeadingStyleType.Heading6} upperCase>
            {t('placeOrder.locationDialog.title')}
          </Title>
          <InfoText styleType={ParagraphStyleType.body16}>
            {t('placeOrder.locationDialog.text')}
          </InfoText>
          {locations.length >= locationLimitSearch ? (
            <InputWrapper>
              <SearchInput
                id="search-input"
                onClear={() => {
                  setSearchString('');
                }}
                onChange={(e) => {
                  onFilter(e.target.value);
                }}
                autoComplete="organization || street-address"
                value={searchString}
                crossOrigin={undefined}
                {...typeFix}
                placeholder={t('placeOrder.locationDialog.choose.location')}
              />
            </InputWrapper>
          ) : (
            <ListTitle styleType={ParagraphStyleType.body16}>
              {t('placeOrder.locationDialog.choose.location')}
            </ListTitle>
          )}
          {searchList && !searchLoading && searchString && searchString.length >= 3 ? (
            <RadioButtonList
              radioItems={searchList.map(({ dwKey, id, name, address, description }) => ({
                id: dwKey,
                title: name,
                subtitle: description,
                description: `${address.street}, ${address.city} (${id})`,
              }))}
              handleOnChange={handleOnLocationChange}
              selectedId={selectedLocationId}
              buttonsRight={buttonsRight}
            />
          ) : (
            <RadioButtonList
              radioItems={locations.map(({ dwKey, id, name, address, description }) => ({
                id: dwKey,
                title: name,
                subtitle: description,
                description: `${address.street}, ${address.city} (${id})`,
              }))}
              handleOnChange={handleOnLocationChange}
              selectedId={selectedLocationId}
              buttonsRight={buttonsRight}
            />
          )}
        </InnerContent>

        <Button
          data-testid="save-location"
          onClick={handleOnSave}
          disabled={isSelectedSameAsInitialLocation()}
          color={ButtonColor.blue}
          {...typeFix}
        >
          {t('placeOrder.locationDialog.cta.text')}
        </Button>
      </ContentWrapper>
    </Dialog>
  );
};

export default LocationDialog;
