import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { type Location } from '@stenarecycling/customer-portal-types';
import {
  setSelectedOrder,
  selectSelectedOrder,
  selectSelectedOrderLocation,
} from '#state/ordersSlice';
import { routePaths } from '#routes/routePaths';
import Page from '#components/Page';
import BackButton from '#components/BackButton';
import { useDispatch } from '#utils/hooks/redux';
import { Country } from '#types/location';
import { StatusNumber } from '#utils/orderUtil';
import OrderStatus from './OrderStatus';
import PickUpDate from './PickUpDate';
import OrderDetailsBlock from './OrderDetailsBlock';
import ArticlesBlock from './ArticlesBlock';
import CommentBlock from './CommentBlock';
import { OrderDetailsWrapper } from './styles';

const OrderDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderInfo = '' } = useParams<'orderInfo'>();
  const [locationId, orderId, articleId] = orderInfo.split('__');

  useEffect(() => {
    if (locationId && orderId && articleId) {
      dispatch(setSelectedOrder({ locationId, orderId, articleId }));
    }
  }, []); // eslint-disable-line

  const order = useSelector(selectSelectedOrder);
  const orderLocation: Location | undefined = useSelector(selectSelectedOrderLocation);
  const getStatus = () => {
    if (orderLocation?.country !== Country.NORWAY) {
      return order?.status;
    }

    switch (order?.status) {
      case StatusNumber.one:
        return StatusNumber.three;
      case StatusNumber.two:
        return StatusNumber.four;
      default:
        return order?.status;
    }
  };

  return (
    <Page
      title={`${t('orderDetails.order')} #${orderId}`}
      trackedTitle={t('orderDetails.order.orderDetails', { lng: 'en' })}
      leftActionButton={
        <BackButton to={routePaths.USER_ORDERS} state={{ from: routePaths.USER_ORDERS_DETAILS }} />
      }
    >
      <OrderDetailsWrapper>
        <OrderStatus status={getStatus()} />
        <PickUpDate
          status={getStatus()}
          latestPickupDate={order?.pickupDate.latest}
          plannedPickupDate={order?.plannedPickupDate}
          orderlocation={orderLocation}
        />
        <OrderDetailsBlock {...{ order, orderLocation }} />
        <ArticlesBlock {...{ order }} />
        {orderLocation?.country === Country.SWEDEN && <CommentBlock {...{ order }} />}
      </OrderDetailsWrapper>
    </Page>
  );
};

export default OrderDetails;
