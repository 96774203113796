import { type ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonColor } from '@stenametall/component-library';
import { useSelector } from 'react-redux';
import { Country } from '@stenarecycling/customer-portal-types';
import colors from '#assets/colors';
import Dialog from '#components/Dialog';
import { HeadingStyleType, HeadingType } from '#components/Heading';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import { selectSelectedLocation } from '#state/locationsSlice';
import {
  CharacterLeftParagraph,
  StyledHeading,
  TextArea,
  TextAreaLabel,
  TextWrapper,
} from './styles';

type ArticleCommentDialogProps = {
  id: string;
  title: string;
  description: string;
  comment?: string | null;
  isOpen: boolean;
  onClose: () => void;
  handleOnSaveComment: (id: string, comment: string | null) => void;
};

const ArticleCommentDialog = (props: ArticleCommentDialogProps) => {
  const { id, title, description, comment, isOpen, onClose, handleOnSaveComment } = props;
  const selectedLocation = useSelector(selectSelectedLocation);

  const { t } = useTranslation();
  const textMaxLength = useMemo(() => {
    if (selectedLocation?.country === Country.NORWAY) {
      return 320;
    }

    return 60;
  }, [selectedLocation]);
  const [text, setText] = useState(comment);

  const onSave = () => {
    handleOnSaveComment(id, text as string | null);
    onClose();
  };

  useEffect(() => {
    if (isOpen) setText(comment);
  }, [isOpen, comment]);

  const textAreaOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const hasNoChange = () => {
    return text === comment;
  };

  const commentText = comment ? t('placeOrder.comments.comment') : t('placeOrder.comments.add');

  //Fix for bad types in used lib
  const typeFix = {} as {
    onPointerLeaveCapture: () => void;
    onPointerEnterCapture: () => void;
    placeholder: string;
  };

  return (
    <Dialog {...{ isOpen, onClose }}>
      <StyledHeading styleType={HeadingStyleType.Heading6} headingType={HeadingType.H2} upperCase>
        {commentText}
      </StyledHeading>
      <TextWrapper>
        <Paragraph styleType={ParagraphStyleType.body16}>{title}</Paragraph>
        <Paragraph styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_01}>
          {description}
        </Paragraph>
      </TextWrapper>
      <TextAreaLabel styleType={ParagraphStyleType.body16}>
        {t('placeOrder.comments.comment')}
      </TextAreaLabel>
      <TextArea rows={4} maxLength={textMaxLength} onChange={textAreaOnChange} value={text ?? ''} />
      <CharacterLeftParagraph styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_01}>
        {`${textMaxLength - (text?.length ?? 0)} / ${textMaxLength}`}{' '}
        {t('placeOrder.comments.charactersLeft')}
      </CharacterLeftParagraph>
      <Button onClick={onSave} color={ButtonColor.blue} disabled={hasNoChange()} {...typeFix}>
        {t('placeOrder.comments.save')}
      </Button>
    </Dialog>
  );
};

export default ArticleCommentDialog;
