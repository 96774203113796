export enum Country {
  SWEDEN = 104,
  DENMARK = 555,
  FINLAND = 505,
  POLAND = 105,
  NORWAY = 250,
  CONFIDENTIAL = 999,
}

export type Partner = {
  id: string;
  fireId: string | null;
  dwKey: string;
  parentId: number | string | null;
  sgtId: number;
  name: string;
  childCount?: number;
  locationCount: number;
  companyId: Country;
  customerText: string;
  customerDescription: string;
  customerIdAndDescription: string;
  street: string;
  postalCode: string;
  city: string;
  countryId: string;
  countryName: string;
  description: string;
  additionalDescription: string;
  additionalAddressInfo: string;
  pickupAddressId: string;
  isActivePickupPlace: boolean;
  isActive: boolean;
  type: string;
  hasActiveChildren?: boolean;
  source: string;
  modifiedDate?: string;
  billable: boolean;
  isPriceAgreed: boolean;
  isLocation?: boolean;
  isNBPPilot?: boolean;
  isCS?: boolean;
  organizationNumber: string;
  siteId: string;
};
