import { type ReactNode } from 'react';
import colors from '#assets/colors';
import Heading, { HeadingStyleType } from '#components/Heading';
import LinkListItem from './LinkListItem';
import ListItem from './ListItem';
import { SettingsListHeaderWrapper, SettingsListWrapper } from './styles';

type SettingsListProps = {
  subHeader: string;
  children: ReactNode;
};

const SettingsList = ({ subHeader, children }: SettingsListProps) => {
  return (
    <SettingsListWrapper>
      <SettingsListHeaderWrapper>
        <Heading styleType={HeadingStyleType.Heading7} upperCase fontColor={colors.compCharcoal_00}>
          {subHeader}
        </Heading>
      </SettingsListHeaderWrapper>
      {children}
    </SettingsListWrapper>
  );
};

export default SettingsList;

export { LinkListItem, ListItem };
