import { type ReactNode } from 'react';
import Heading, { HeadingType, HeadingStyleType } from '#components/Heading';
import logo from '#assets/logos/logo_black.svg';
import HeaderWrapper, { FlexItem, ImgWrapper } from './styles';

type HeaderProps = {
  title: string;
  showHeaderLogo?: boolean;
  leftActionButton?: ReactNode;
};

const Header = ({ title, showHeaderLogo = false, leftActionButton }: HeaderProps) => {
  return (
    <HeaderWrapper data-testid="Header">
      <FlexItem>{leftActionButton}</FlexItem>
      <FlexItem>
        {showHeaderLogo ? (
          <ImgWrapper>
            <img src={logo} alt="stena-logo" />
          </ImgWrapper>
        ) : (
          <Heading
            headingType={HeadingType.H1}
            styleType={HeadingStyleType.Heading7}
            center
            upperCase
          >
            {title}
          </Heading>
        )}
      </FlexItem>
      <FlexItem />
    </HeaderWrapper>
  );
};

export default Header;
