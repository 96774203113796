import styled from '@emotion/styled';
import colors from '#assets/colors';
import spacing from '#assets/spacing';

export const SettingsListWrapper = styled.div`
  margin: ${spacing.mediumLow} 0;
  background-color: ${colors.white};
`;

export const SettingsListHeaderWrapper = styled.div`
  padding: ${spacing.xxsmall} ${spacing.small};
`;
