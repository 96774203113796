type Co2ArticleDataType = Record<
  number,
  {
    name: string;
    price: number;
    currency: string;
    code: number;
    trialLengthDays: number;
  }
>;

export const co2ArticleData: Co2ArticleDataType = {
  104: {
    name: 'CO2 Impact Plus',
    price: 990,
    currency: 'SEK',
    code: 4600,
    trialLengthDays: 0,
  },
  555: {
    name: 'Co2 Impact Plus, Abonnement',
    price: 664,
    currency: 'DKK',
    code: 890000,
    trialLengthDays: 0,
  },
};
