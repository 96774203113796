import {
  type ChangeEvent,
  type FocusEvent,
  type InputHTMLAttributes,
  type ReactNode,
  useEffect,
  useState,
} from 'react';
import { checkNumberRange, handleMaxLength } from '../../../helpers/inputHelpers';

import { IconButton } from '../../IconButton';
import { Eye, EyeHide, Info } from '../../../Icons';
import { Tooltip } from '../../../Data/Tooltip';
import { colors } from '../../../../themes';
import {
  InnerLabelWrapper,
  InputLabel,
  OuterLabelWrapper,
  RequiredIndicator,
} from '../../../style';
import { type InputType } from './types';
import {
  InputFieldWrapper,
  InputSuffix,
  ShowPasswordWrapper,
  StyledInput,
  StyledInputWrapper,
  TextfieldError,
  TextfieldWrapper,
  TooltipWrapper,
} from './style';

type InputWrapperProps = TextfieldProps & {
  children?: ReactNode;
};

const InputWrapper = ({
  label,
  fullWidth,
  errorMessage,
  children,
  toolTipContent,
  required,
  ...rest
}: InputWrapperProps) => {
  const { name, mb } = rest;

  return (
    <TextfieldWrapper fullWidth={fullWidth} mb={mb}>
      <InputFieldWrapper>
        <OuterLabelWrapper>
          {label && (
            <InnerLabelWrapper>
              <InputLabel htmlFor={name}>{label}</InputLabel>
              {!!required && <RequiredIndicator>*</RequiredIndicator>}
            </InnerLabelWrapper>
          )}
          {toolTipContent && (
            <TooltipWrapper>
              <Tooltip
                hoverItem={
                  <Info color={colors.primary.blue} width="22px" height="22px" cursor="pointer" />
                }
              >
                {toolTipContent}
              </Tooltip>
            </TooltipWrapper>
          )}
        </OuterLabelWrapper>
        {children}
      </InputFieldWrapper>

      {!!errorMessage && <TextfieldError>{errorMessage}</TextfieldError>}
    </TextfieldWrapper>
  );
};

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  type?: InputType;
  // Used by the PasswordInput component that
  // has a toggle to show/hide the password
  originalType?: InputType;
  value?: string | number;
  placeholder?: string;
  max?: number;
  min?: number;
  disabled?: boolean;
  checkPassword?: boolean;
  required?: boolean;
  suffix?: string;
  errorMessage?: string;
  name?: string;
  autoComplete?: string;
  'data-testid'?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
};

const InputBase = ({ ...rest }: InputProps) => {
  const { name, maxLength, onChange, suffix } = rest;

  const dataTestId = rest['data-testid'];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (maxLength) {
      value = handleMaxLength(value, maxLength);
    }

    onChange?.({ ...e, target: { ...e.target, value } });
  };

  return (
    <StyledInputWrapper>
      {suffix && <InputSuffix disabled={rest.disabled}>{suffix}</InputSuffix>}
      <StyledInput
        {...rest}
        data-testid={dataTestId ?? 'text-field'}
        id={name}
        onChange={handleChange}
      />
    </StyledInputWrapper>
  );
};

const PasswordInput = ({ ...rest }: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState<InputType>('password');

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    setInputType(showPassword ? 'password' : 'text');
  };

  return (
    <>
      <InputBase {...rest} type={inputType} originalType="password" />
      <ShowPasswordWrapper>
        <IconButton variant="secondary" onClick={toggleShowPassword}>
          {showPassword ? <EyeHide /> : <Eye />}
        </IconButton>
      </ShowPasswordWrapper>
    </>
  );
};

const NumberInput = (inputProps: InputProps) => {
  const _props = { ...inputProps, min: inputProps.min ?? 0 };
  const { max, maxLength, min } = _props;
  const [currentValue, setCurrentValue] = useState(_props.value ?? '');

  useEffect(() => {
    if (_props.value === undefined) {
      setCurrentValue('');
    } else {
      setCurrentValue(_props.value);
    }
  }, [_props.value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (!value) {
      setCurrentValue('');
      sendOnchange(e, '');

      return;
    }

    if (maxLength) {
      value = handleMaxLength(value, maxLength);
    }

    if (value.length) {
      const { numberIsInRange, numberTooHigh } = checkNumberRange({ value, max, min });
      const numberToSet = numberTooHigh ? max : value;

      setCurrentValue(`${Number(numberToSet)}`);
      if (numberIsInRange) sendOnchange(e);
    } else {
      setCurrentValue('');
    }
  };

  useEffect(() => {
    const { numberToUse, numberIsInRange } = checkNumberRange({
      value: `${Number(currentValue)}`,
      max,
      min,
    });

    if (!numberIsInRange) {
      setCurrentValue(numberToUse);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on MIN/MAX change.
  }, [min, max]);

  const sendOnchange = (e: React.ChangeEvent<HTMLInputElement>, number?: string) => {
    const numberToUse = number ?? e.target.value;

    const valueToSend: string | undefined = numberToUse.length ? `${Number(numberToUse)}` : '';

    if (_props.onChange) {
      _props.onChange({ ...e, target: { ...e.target, value: valueToSend } });
    }
  };

  return (
    <InputBase
      type="number"
      onWheel={(e) => {
        e.currentTarget.blur();
      }}
      onKeyDown={(e) => {
        if (e.key === 'e' || e.key === '.') {
          e.preventDefault();
        }
      }}
      onChange={(e) => {
        handleChange(e);
      }}
      {..._props}
    />
  );
};

const EmailInput = ({ ...rest }: InputProps) => {
  return <InputBase {...rest} type="email" />;
};

export type TextfieldProps = InputProps & {
  label?: string;
  fullWidth?: boolean;
  errorMessage?: string;
  toolTipContent?: ReactNode;
  mb?: string;
};

export const Textfield = ({ label, fullWidth, ...rest }: TextfieldProps) => {
  let { type } = rest;

  if (!type) {
    type = 'text';
  }

  const getInputMarkup = (type: InputType) => {
    switch (type) {
      case 'password':
        return <PasswordInput {...rest} />;
      case 'number':
        return <NumberInput {...rest} />;
      case 'email':
        return <EmailInput {...rest} />;
      case 'tel':
        return <InputBase {...rest} type={type} />;
      default:
        return <InputBase {...rest} />;
    }
  };

  return (
    <InputWrapper {...rest} label={label} fullWidth={fullWidth}>
      {getInputMarkup(type)}
    </InputWrapper>
  );
};
