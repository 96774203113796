import styled from '@emotion/styled';
import colors from '#assets/colors';
import spacing from '#assets/spacing';
import Paragraph from '#components/Paragraph';

export const LikedButton = styled.button<{ isActive: boolean | undefined }>`
  margin: 4px;
  opacity: ${({ isActive }) => (isActive ? '100%' : isActive === undefined ? '100%' : '50%')};
  cursor: pointer;
  min-height: 158px;
  min-width: 158px;
  border-style: none;
  border: 2px solid ${colors.primGrey_03};
  border-radius: 8px;
  padding: 16px;
  background-color: ${colors.primGrey_03};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 340px) {
    min-height: 140px;
    min-width: 140px;
  }
`;

export const ImgWrapper = styled.div<{
  thumbDown: boolean;
  isActive?: boolean;
}>`
  margin-bottom: ${spacing.xsmall};
  > svg {
    fill: ${({ isActive }) => (isActive ? colors.black : colors.primGrey_00)};
    transform: ${({ thumbDown }) => (thumbDown ? `rotate(180deg)` : `rotate(0deg)`)};
  }
`;

export const TextParagraph = styled(Paragraph)`
  font-family: StenaSans;
`;
