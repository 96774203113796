import { useEffect, useRef } from 'react';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { useProfileV2 } from '#lib/Profile';

export const NbpPilotFlagReset = () => {
  const { flagsReady } = useFlagsStatus();
  const nbpPilotFlag = useFlag('nbp-pilot');
  const previousNbpPilot = useRef<boolean | null>(null);
  const profileContext = useProfileV2();

  useEffect(() => {
    if (!flagsReady) return;

    // If flag changes during the session, update the profile to load the new partners and permissions
    if (previousNbpPilot.current !== null && previousNbpPilot.current !== nbpPilotFlag) {
      void profileContext.updateProfile();
    }

    previousNbpPilot.current = nbpPilotFlag;
  }, [flagsReady, nbpPilotFlag, profileContext]);

  return null;
};
