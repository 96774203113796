import styled from '@emotion/styled';
import colors from '#assets/colors';
import spacing from '#assets/spacing';

const CookiesWrapper = styled.div`
  padding: ${spacing.small};
  background-color: ${colors.white};
  min-height: 100%;
`;

export default CookiesWrapper;
