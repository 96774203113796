import { type ReactNode } from 'react';
import StyledAnchorButton from './styles';

export type AnchorButtonProps = {
  children: ReactNode;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  disabled?: boolean;
  upperCase?: boolean;
  bgColor?: string;
  target?: string;
};
function AnchorButton({
  children,
  href = '',
  onClick = () => true,
  disabled,
  upperCase = false,
  bgColor = '',
  target,
}: AnchorButtonProps) {
  return (
    <StyledAnchorButton
      href={href}
      onClick={
        !disabled
          ? onClick
          : (e) => {
              e.preventDefault();
            }
      }
      target={target ?? '_self'}
      upperCase={upperCase}
      bgColor={bgColor}
    >
      {children}
    </StyledAnchorButton>
  );
}

export default AnchorButton;
