import { useTranslation } from 'react-i18next';
import { HeadingStyleType, HeadingType } from '#components/Heading';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import StatusList from './StatusList';
import { OrderStatusWrapper, RejectedStatusTitle } from './styles';
type OrderStatusProps = {
  status?: string;
};

const StatusRejected = () => {
  const { t } = useTranslation();

  return (
    <>
      <RejectedStatusTitle styleType={HeadingStyleType.Heading6} headingType={HeadingType.H2}>
        {t('orderDetails.order.rejected')}
      </RejectedStatusTitle>
      <Paragraph styleType={ParagraphStyleType.body16}>
        {t('orderDetails.order.rejected.message')}
      </Paragraph>
    </>
  );
};

const OrderStatus = ({ status }: OrderStatusProps) => {
  return (
    <OrderStatusWrapper>
      {status === '5' && <StatusRejected />}
      {status !== '5' && <StatusList status={status} />}
    </OrderStatusWrapper>
  );
};

export default OrderStatus;
