import styled from '@emotion/styled';
import spacing from '#assets/spacing';
import Paragraph from '#components/Paragraph';

export const Name = styled(Paragraph)``;

export const Description = styled(Paragraph)``;

export const Address = styled(Paragraph)`
  margin-bottom: ${spacing.small};
`;
