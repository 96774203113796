import dayjs from 'dayjs';
import TagManager from 'react-gtm-module';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { routePaths } from '#routes/routePaths';
import {
  type LocalStorageInstallPWADialogData,
  getInstallPWADialogData,
  saveInstallPWADialogData,
} from '#utils/localStorageUtil';
import { isCurrentRoute } from '#utils/routeUtil';
import { isIos } from '#utils/userAgentUtil';

const checkWithLocalStorage = (
  localStorageData: Partial<LocalStorageInstallPWADialogData> | undefined,
) => {
  //If first time don't show and save the date
  if (!localStorageData || haveNotShownDialogBefore(localStorageData)) {
    saveInstallPWADialogData({ skippedFirstTime: new Date() });

    return false;
  }

  if (haveShownDialogWithinTheLastWeek(localStorageData)) {
    return false;
  }

  return true;
};

const haveNotShownDialogBefore = (
  localStorageData: Partial<LocalStorageInstallPWADialogData> | undefined,
): localStorageData is undefined => {
  return !localStorageData?.skippedFirstTime;
};

const haveShownDialogWithinTheLastWeek = (
  localStorageData: Partial<LocalStorageInstallPWADialogData> | undefined,
) => {
  if (localStorageData?.lastShown) {
    return isLessThanOneWeekAgo(new Date(localStorageData.lastShown));
  }

  return false;
};

const isLessThanOneWeekAgo = (date: Date) => {
  const numberOfDays = 7;
  const now = dayjs();
  const convertedDate = dayjs(date);

  return now.diff(convertedDate, 'day') < numberOfDays;
};

export const useInstallPWADialog = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    const localStorageData = getInstallPWADialogData();

    if (localStorageData) {
      const timesShown = localStorageData.timesShown ? localStorageData.timesShown + 1 : 1;
      const skippedFirstTime = localStorageData.skippedFirstTime;
      const lastShown = new Date();

      saveInstallPWADialogData({ lastShown, timesShown, skippedFirstTime });
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'InstallPWADialogShown',
      },
    });

    setIsOpen(false);
  };

  useEffect(() => {
    const localStorageData = getInstallPWADialogData();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nav: any = window.navigator;
    const isStandaloneMode = () => 'standalone' in nav && nav.standalone;
    const isOnStartPage = isCurrentRoute(routePaths.PLACE_ORDER, location);

    const showInstallPWADialog =
      isIos() && !isStandaloneMode() && isOnStartPage && checkWithLocalStorage(localStorageData);

    if (showInstallPWADialog) {
      setIsOpen(true);
    }
  }, [location]);

  useEffect(() => {
    if (!isOpen) return;

    const handleOnKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close();
      }
    };

    document.addEventListener('keydown', handleOnKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown, false);
    };
  }, [isOpen]);

  return { isOpen, open, close };
};
