import { useSelector } from 'react-redux';
import { selectLocations } from '#state/locationsSlice';
import { selectOrders } from '#state/ordersSlice';
import Page from '#components/Page';
import OrderGroup from './OrderGroup';
import OrdersListWrapper from './styles';

const OrdersList = () => {
  const locations = useSelector(selectLocations);
  const orders = useSelector(selectOrders);

  const filterLocations = locations.filter((item, pos) => {
    const firstLocation = locations.findIndex((location) => location.dwKey === item.dwKey);

    return firstLocation == pos;
  });

  return (
    <Page trackedTitle="My Orders">
      <OrdersListWrapper>
        {filterLocations.map((location) => {
          const orderItems = orders[location.dwKey];

          if (orderItems && orderItems.length > 0) {
            return <OrderGroup {...{ location, orderItems }} key={location.dwKey} />;
          }

          return null;
        })}
      </OrdersListWrapper>
    </Page>
  );
};

export default OrdersList;
