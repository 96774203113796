import { useTranslation } from 'react-i18next';
import { routePaths } from '#routes/routePaths';
import Page from '#components/Page';
import BackButton from '#components/BackButton';
import { HeadingStyleType, HeadingType } from '#components/Heading';
import { ParagraphStyleType } from '#components/Paragraph';
import PrivacyInformationWrapper, {
  BlockATag,
  StyledGlobeIcon,
  StyledParagraph,
  Title,
} from './styles';

const PrivacyInformation = () => {
  const { t } = useTranslation();

  return (
    <Page
      title={t('privacyInformation.pageTitle')}
      trackedTitle={t('privacyInformation.pageTitle', { lng: 'en' })}
      leftActionButton={
        <BackButton to={routePaths.SETTINGS} state={{ from: routePaths.PRIVACY_INFORMATION }} />
      }
    >
      <PrivacyInformationWrapper>
        <Title headingType={HeadingType.H1} styleType={HeadingStyleType.Heading4}>
          {t('privacyInformation.title')}
        </Title>
        <StyledParagraph styleType={ParagraphStyleType.body16}>
          {t('privacyInformation.text')}
        </StyledParagraph>
        <BlockATag
          target="_blank"
          href="https://www.stenarecycling.com/privacy/privacy-information/"
        >
          {t('privacyInformation.link.text.1')} <StyledGlobeIcon />
        </BlockATag>
        <BlockATag
          target="_blank"
          href="https://www.stenarecycling.com/privacy/video-surveillance/"
        >
          {t('privacyInformation.link.text.2')} <StyledGlobeIcon />
        </BlockATag>
      </PrivacyInformationWrapper>
    </Page>
  );
};

export default PrivacyInformation;
