import styled from '@emotion/styled';
import colors from '#assets/colors';
import spacing from '#assets/spacing';
import Paragraph from '#components/Paragraph';

const ChangeLanguageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${spacing.mediumLow} ${spacing.small};
  background-color: ${colors.white};
  min-height: calc(100vh - 56px - 88px);
`;

export const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${spacing.medium};
`;

export const StyledParagraphSmallListTitle = styled(Paragraph)`
  margin-bottom: ${spacing.xxsmall};
`;

export default ChangeLanguageWrapper;
