import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import colors from '#assets/colors';
import spacing from '#assets/spacing';
import ArrowRight from '#assets/icons/arrow-right.svg?react';

export const LinkListItemWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: ${spacing.xsmall} ${spacing.small};
  cursor: pointer;
  border-top: 1px solid ${colors.primGrey_03};
  &:last-child {
    border-bottom: 1px solid ${colors.primGrey_03};
  }
`;

export const LinkAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ArrowBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledUserIcon = styled(ArrowRight)`
  width: 28px;
  height: 28px;
  path {
    stroke: #327ea5;
  }
`;
