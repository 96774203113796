import styled from '@emotion/styled';
import spacing from '#assets/spacing';
import Heading from '#components/Heading';
import Paragraph from '#components/Paragraph';
import colors from '#assets/colors';

export const OrderSuccessWrapper = styled.div`
  height: 100%;
`;

export const OrderSuccessHeadBackground = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${spacing.mediumLow} ${spacing.small};
  background-color: ${colors.compBeige_03};
`;

export const StyledHeading = styled(Heading)`
  margin-top: ${spacing.small};
`;

export const StyledHeading2 = styled(Heading)`
  margin-top: ${spacing.mediumLow};
  margin-bottom: ${spacing.xxsmall};
`;

export const StyledParagraph = styled(Paragraph)`
  margin-top: ${spacing.xxsmall};
  margin-bottom: ${spacing.xsmall};
`;

export const OrderSummaryWrapper = styled.div`
  width: 100%;
  padding: 0 ${spacing.small};
  margin-bottom: ${spacing.mediumHigh};
`;
