import { useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import BackButton from '#components/BackButton';
import Page from '#components/Page';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import { routePaths } from '#routes/routePaths';
import { sendMail, type IDefaultMail } from '#api/mail';
import LikeButton from './LikeButton';
import {
  FeedbackAndHelpWrapper,
  BottomWrapper,
  TopContentWrapper,
  TopButtonWrapper,
  TopParagraph,
  FeedbackContainer,
  FeedbackSentContainer,
  SubmitButton,
  TextArea,
  TextAreaParagraph,
  ClappingEmojiContainer,
  ThanksBoldParagraph,
  ThanksTextParagraph,
  PrivacyLink,
} from './styles';

const FeedbackAndHelp = () => {
  const FEEDBACK_MAIL = 'digitalservices@stenarecycling.se';
  const FEEDBACK_LIKED = 'upvote';
  const FEEDBACK_NOT_LIKED = 'downvote';
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
  const [isLiked, setIsLiked] = useState<boolean | undefined>(undefined);
  const [isDisliked, setIsDisliked] = useState<boolean | undefined>(undefined);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (message: string, isLiked: boolean) => {
    tagManagerEvent(isLiked ? FEEDBACK_LIKED : FEEDBACK_NOT_LIKED);

    const date = new Date().toLocaleTimeString('sv-SE', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    const mail: IDefaultMail = {
      toEmail: FEEDBACK_MAIL,
      subject: 'Order pickup feedback',
      message: `<h4>The order pickup app was ${
        isLiked ? 'liked' : 'disliked'
      }.</h4><br><p>${message}</p><br><p>${date}</p>`,
    };

    sendMail(mail)
      .then(() => {
        setError(false);
        setIsFeedbackSent(true);
      })
      .catch((error) => {
        setError(true);
        setIsFeedbackSent(true);
        console.log('error', error);
      });
  };

  const tagManagerEvent = (eventName: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
      },
    });
  };

  return (
    <Page
      title={t('feedback.pageTitle')}
      trackedTitle={t('feedback.pageTitle', { lng: 'en' })}
      leftActionButton={
        <BackButton to={routePaths.SETTINGS} state={{ from: routePaths.FEEDBACK_AND_HELP }} />
      }
    >
      <FeedbackAndHelpWrapper>
        <TopContentWrapper>
          <TopParagraph styleType={ParagraphStyleType.body16}>
            {t('feedback.firstQuestion')}
          </TopParagraph>
          <TopButtonWrapper>
            <LikeButton
              data-testid="liked-button"
              disabled={isFeedbackSent}
              isActive={isLiked}
              onClick={() => {
                setIsLiked(true);
                setIsDisliked(false);
              }}
            >
              {t('feedback.likeButtonText')}
            </LikeButton>
            <LikeButton
              data-testid="disliked-button"
              disabled={isFeedbackSent}
              isActive={isDisliked}
              thumbDown
              onClick={() => {
                setIsDisliked(true);
                setIsLiked(false);
              }}
            >
              {t('feedback.dislikeButtonText')}
            </LikeButton>
          </TopButtonWrapper>
        </TopContentWrapper>
        {isLiked !== undefined && (
          <BottomWrapper>
            {!isFeedbackSent && (
              <FeedbackContainer>
                <TextAreaParagraph styleType={ParagraphStyleType.body16}>
                  {t('feedback.ImproveText')}
                </TextAreaParagraph>
                <TextArea
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  rows={4}
                ></TextArea>
                <SubmitButton
                  data-testid="submit-button"
                  disabled={disabledSubmitButton}
                  onClick={() => {
                    setDisabledSubmitButton(true);
                    onSubmit(message, isLiked);
                  }}
                >
                  {t('feedback.submitButtonText')}
                </SubmitButton>
                <Paragraph styleType={ParagraphStyleType.body12}>
                  {t('feedback.privacyText')}{' '}
                  <PrivacyLink to={routePaths.PRIVACY_INFORMATION}>
                    {t('feedback.privacyLink')}
                  </PrivacyLink>
                </Paragraph>
              </FeedbackContainer>
            )}
            {isFeedbackSent && (
              <FeedbackSentContainer>
                {error ? (
                  <>
                    <ClappingEmojiContainer>😔</ClappingEmojiContainer>
                    <ThanksBoldParagraph bold styleType={ParagraphStyleType.body18}>
                      {t('feedback.errorMessage')}
                    </ThanksBoldParagraph>
                  </>
                ) : (
                  <>
                    <ClappingEmojiContainer>👏️</ClappingEmojiContainer>
                    <ThanksBoldParagraph bold styleType={ParagraphStyleType.body18}>
                      {t('feedback.thankyouTitle')}
                    </ThanksBoldParagraph>
                    <ThanksTextParagraph styleType={ParagraphStyleType.body18}>
                      {t('feedback.thankyouMessage')}
                    </ThanksTextParagraph>
                  </>
                )}
              </FeedbackSentContainer>
            )}
          </BottomWrapper>
        )}
      </FeedbackAndHelpWrapper>
    </Page>
  );
};

export default FeedbackAndHelp;
