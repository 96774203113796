import { StyledATag } from './styles';

export type ATagProps = {
  children: React.ReactNode;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  disabled?: boolean;
};

function ATag({ children, href = '', onClick = () => true, disabled = false }: ATagProps) {
  return (
    <StyledATag
      href={href}
      onClick={
        !disabled
          ? onClick
          : (e) => {
              e.preventDefault();
            }
      }
    >
      {children}
    </StyledATag>
  );
}

export default ATag;
