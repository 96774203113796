import { useTranslation } from 'react-i18next';
import { Accordion } from '#components/Accordion';
import BackButton from '#components/BackButton';
import ATag from '#components/ATag';
import Page from '#components/Page';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import { routePaths } from '#routes/routePaths';
import Heading, { HeadingStyleType, HeadingType } from '#components/Heading';
import { ContactInfoSection } from '../YourBranch/BranchContactInfo/styles';
import CustomerServiceWrapper, { CustomerServiceTitle, StyledParagraph, StyledA } from './styles';

const CustomerService = () => {
  const { t } = useTranslation();

  return (
    <Page
      title={t('userProfile.customerService.title')}
      trackedTitle={t('userProfile.customerService.title', { lng: 'en' })}
      leftActionButton={
        <BackButton to={routePaths.SETTINGS} state={{ from: routePaths.CUSTOMER_SERVICE }} />
      }
    >
      <CustomerServiceWrapper>
        <CustomerServiceTitle>
          <Paragraph styleType={ParagraphStyleType.body16}>
            {t('userProfile.customerservice.info')}
          </Paragraph>
        </CustomerServiceTitle>
        <Accordion
          openItem={null}
          items={[
            {
              summary: 'Denmark',
              details: (
                <ContactInfoSection>
                  <StyledParagraph styleType={ParagraphStyleType.body16}>
                    {t('userProfile.customerservice.dk.info1')}
                    <StyledA href="tel:004556679200">+45 56 679 200</StyledA>
                    {t('userProfile.customerservice.dk.info2')}
                  </StyledParagraph>
                  <Heading headingType={HeadingType.H6} styleType={HeadingStyleType.Heading6}>
                    Ost
                  </Heading>
                  <ATag href="mailto:dk.kundesupport.ost@stenarecycling.com">
                    dk.kundesupport.ost@stenarecycling.com
                  </ATag>
                  <StyledParagraph styleType={ParagraphStyleType.body16}>
                    (Sjælland + Bornholm)
                  </StyledParagraph>
                  <Heading headingType={HeadingType.H6} styleType={HeadingStyleType.Heading6}>
                    Syd
                  </Heading>
                  <ATag href="mailto:dk.kundesupport.syd@stenarecycling.com">
                    dk.kundesupport.syd@stenarecycling.com
                  </ATag>
                  <StyledParagraph styleType={ParagraphStyleType.body16}>
                    (Sydjylland + Fyn)
                  </StyledParagraph>

                  <Heading headingType={HeadingType.H6} styleType={HeadingStyleType.Heading6}>
                    Midt
                  </Heading>
                  <ATag href="mailto:dk.kundesupport.midt@stenarecycling.com">
                    dk.kundesupport.midt@stenarecycling.com
                  </ATag>
                  <StyledParagraph styleType={ParagraphStyleType.body16}>
                    HeadingType
                  </StyledParagraph>

                  <Heading headingType={HeadingType.H6} styleType={HeadingStyleType.Heading6}>
                    Nord
                  </Heading>
                  <ATag href="mailto:dk.kundesupport.nord@stenarecycling.com">
                    dk.kundesupport.nord@stenarecycling.com
                  </ATag>
                  <Paragraph styleType={ParagraphStyleType.body16}>(Nordjylland)</Paragraph>
                </ContactInfoSection>
              ),
            },
            {
              summary: 'Finland',
              details: (
                <ContactInfoSection>
                  <Paragraph styleType={ParagraphStyleType.body14} upperCase>
                    {t('userProfile.email.subHeader')}
                  </Paragraph>
                  <ATag href="mailto:asiakaspalvelu@stenarecycling.fi">
                    asiakaspalvelu@stenarecycling.fi
                  </ATag>
                </ContactInfoSection>
              ),
            },
            {
              summary: 'Norway',
              details: (
                <ContactInfoSection>
                  <Paragraph styleType={ParagraphStyleType.body14} upperCase>
                    {t('userProfile.email.subHeader')}
                  </Paragraph>
                  <ATag href="mailto:customerportal@stenarecycling.no">
                    customerportal@stenarecycling.no
                  </ATag>
                </ContactInfoSection>
              ),
            },
            {
              summary: 'Sweden',
              details: (
                <ContactInfoSection>
                  <Paragraph styleType={ParagraphStyleType.body14} upperCase>
                    {t('userProfile.email.subHeader')}
                  </Paragraph>
                  <ATag href="mailto:customerportal@stenarecycling.se">
                    customerportal@stenarecycling.se
                  </ATag>
                </ContactInfoSection>
              ),
            },
          ]}
        />
      </CustomerServiceWrapper>
    </Page>
  );
};

export default CustomerService;
