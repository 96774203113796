import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import colors from '#assets/colors';
import PinIcon from '#assets/icons/pin.svg?react';

export const CircleMotion = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: ${colors.primGreen_00};
`;

export const StyledPinIcon = styled(PinIcon)`
  path {
    stroke: ${colors.white};
  }
`;
