import { createContext, type ReactNode } from 'react';
import { type CountryCode } from '@stenarecycling/customer-portal-types';
import { useCountries } from '../hooks/useCountries';
import { getStartUrl } from '../utils/environment';
import type { MenuService } from './useServices';

const getServices = (): MenuService[] => {
  const startUrl = getStartUrl();

  return [
    {
      id: '1',
      name: 'services.order.pickup.name',
      description: 'services.order.pickup.description',
      link: `${startUrl}pickups`,
      linkText: 'services.order.pickup.linktext',
      availableInCountries: 'DK,FI,NO,SE',
      showInTopMenu: true,
    },
    {
      id: '2',
      name: 'services.follow.up.name',
      description: 'services.follow.up.description',
      link: `${startUrl}followup`,
      linkText: 'services.follow.up.linktext',
      availableInCountries: 'DK,FI,NO,SE,PL',
      showInTopMenu: true,
    },
  ];
};

type ServiceProviderProps = { children: ReactNode };
export const ServiceContext = createContext<{
  //** All services for user */
  services: MenuService[];
  loading: boolean;
} | null>(null);

export const ServiceProvider = ({ children }: ServiceProviderProps) => {
  const { supportedCountryCodes } = useCountries();

  const services = getServices().filter((s) => {
    const countriesAvailable = s.availableInCountries.split(',');

    return countriesAvailable.some((c) => supportedCountryCodes.includes(c as CountryCode));
  });

  return (
    <ServiceContext.Provider value={{ services, loading: false }}>
      {children}
    </ServiceContext.Provider>
  );
};
