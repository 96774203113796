import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, spacing } from '../../../themes';
import { boxShadow } from '../../helpers/styleHelpers';
import { type ContainerProps } from '.';

export const ContainerWrapper = styled.div<ContainerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? colors.base.white};
  display: flex;
  flex-direction: column;
  padding: ${spacing.small} ${spacing.mediumLow};
  flex-grow: 1;
  ${({ hideOverflow }) => (hideOverflow ? css`overflow: hidden'` : undefined)};

  ${({ p }) => (p ? `padding: ${spacing[p]}` : '')};
  ${({ outline, outlineColor }) =>
    outline && `border: 1px solid ${outlineColor ?? colors.monochrome.grey30};`};
  ${({ shadow }) => shadow && `box-shadow: ${boxShadow}`};
  ${({ square }) => !square && `border-radius: ${spacing.xxsmall};`};
`;
