import { getFromLocalStorage, saveToLocalStorage } from '#utils/localStorageUtil';
import { type EasterEgg } from '..';

const newApiFlag: EasterEgg = {
  eventTarget: document,
  eventType: 'keyup',
  callback: (sequence: KeyboardEvent[]) => {
    const newApiFlagTrigger = sequence
      .map((event) => event.key)
      .join('')
      .includes('newapi');

    if (newApiFlagTrigger) {
      if (getFromLocalStorage('ff-newapi') === 'true') {
        saveToLocalStorage('ff-newapi', 'false');
      } else {
        saveToLocalStorage('ff-newapi', 'true');
      }

      return true;
    }

    return false;
  },
};

export default newApiFlag;
