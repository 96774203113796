import styled from '@emotion/styled';
import colors from '#assets/colors';
import fontSizes from '#assets/fontSizes';
import spacing from '#assets/spacing';
import Paragraph from '#components/Paragraph';
import Icon from '#assets/icons/info.svg?react';

export const WeightInputWrapper = styled.div`
  background: ${colors.beige};
  margin: 0 -${spacing.small};
`;

export const Title = styled(Paragraph)`
  color: ${colors.compCharcoal_00};
`;

export const Content = styled.div`
  margin: 0 ${spacing.small};
  padding: 12px 0 ${spacing.small} 0;
  border-top: 1px solid ${colors.compBeige_02};
`;

export const WeightInput = styled.input`
  height: ${spacing.mediumHigh};
  width: 100%;
  border: 1px solid ${colors.primGrey_00};
  background-color: ${colors.white};
  color: ${colors.primGrey_01};
  font-size: ${fontSizes.body18};
  padding: 12px;
  margin-top: -12px;
`;

export const KiloLabel = styled.label`
  float: right;
  position: relative;
  top: 22px;
  right: 12px;
  font-family: StenaSans;
  color: ${colors.primGrey_01};
  font-size: ${fontSizes.body18};
`;

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HazardousWasteInfoSweden = styled.a`
  cursor: pointer;
`;

export const InfoIcon = styled(Icon)`
  margin: 0;
`;

export default WeightInputWrapper;
