import styled from '@emotion/styled';
import { colors } from 'component-library';
import spacing from '#assets/spacing';
import Heading from '#components/Heading';
import ChevronIcon from '#assets/icons/chevron-down.svg?react';

type AccordionProps = {
  isOpen: boolean;
};

const AccordionWrapper = styled.div`
  border-top: 1px solid ${colors.secondary.beigeTint1};
`;

const ItemWrapper = styled.div<AccordionProps>`
  background-color: ${(props) => (props.isOpen ? colors.secondary.beigeTint1 : colors.base.white)};
  padding: ${spacing.xsmall} ${spacing.small};
  border-bottom: 1px solid ${colors.secondary.beigeTint1};
  transistion: background-color 0.3s ease;
`;

const ItemSummary = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemSummaryHeading = styled(Heading)`
  padding-top: 5px;
`;

const ItemDetails = styled.div<AccordionProps>`
  max-height: ${(props) => (props.isOpen ? '500px' : '0')};
  display: block !important;
  transform: translateY(${(props) => (props.isOpen ? '1em' : '0')});
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  transition: all 0.3s ease;
`;

const IconWrapper = styled.div<AccordionProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${(props) => (props.isOpen ? colors.base.white : colors.secondary.beigeTint1)};
  transition: background-color 0.3s ease;
`;

const Icon = styled(ChevronIcon)`
  path {
    stroke: ${colors.base.black};
  }
  circle {
    stroke: ${colors.base.black};
  }
`;

const Wrapper = styled.div<AccordionProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s ease;
`;

export {
  AccordionWrapper,
  ItemWrapper,
  ItemSummary,
  ItemSummaryHeading,
  ItemDetails,
  IconWrapper,
  Icon,
  Wrapper,
};
