import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Message, spacing } from 'component-library';
import { type Location } from '@stenarecycling/customer-portal-types';
import { Country } from '#types/location';
import { type ArticleItemType, type ArticleGroupType } from '#types/article';
import { type OrderItemType } from '#types/order';
import { createGroups } from '#utils/articleUtil';
import { HeadingType, HeadingStyleType } from '#components/Heading';
import ArticleGroup from './ArticleGroup';
import ArticlesListWrapper, { StyledHeading } from './styles';

export type ArticlesListProps = {
  articles: ArticleItemType[];
  decrement: (id: string) => void;
  increment: (id: string) => void;
  handleOnSaveComment: (id: string, comment: string | null) => void;
  handleOnSaveWeight: (id: string, weight: number) => void;
  selectedLocation: Location | null;
  activeOrdersInSelectedLocation?: OrderItemType[];
  country?: Country;
};

const ArticlesList = ({ articles, country, ...rest }: ArticlesListProps) => {
  const { t } = useTranslation();

  const _isConfidential = country === Country.CONFIDENTIAL;

  const [articleGroups, setArticleGroups] = useState<ArticleGroupType[]>([]);

  useEffect(() => {
    setArticleGroups(createGroups(articles));
  }, [articles]);

  return (
    <ArticlesListWrapper>
      {_isConfidential && (
        <Message
          mb={spacing.small}
          title={t('place.order.confidential.no.date.message.title')}
          description={t('place.order.confidential.no.date.message.desc')}
        />
      )}
      <StyledHeading styleType={HeadingStyleType.Heading6} headingType={HeadingType.H2} upperCase>
        {t('placeOrder.articles.select')}
      </StyledHeading>

      {articleGroups.map((group) => (
        <ArticleGroup {...group} {...rest} key={`group-${group.name}`} />
      ))}
    </ArticlesListWrapper>
  );
};

export default ArticlesList;
