import { css } from '@emotion/react';
import styled from '@emotion/styled';
import spacing from '#assets/spacing';
import AnchorButton from '#components/AnchorButton';
import Heading from '#components/Heading';
import Paragraph from '#components/Paragraph';

export const ErrorWrapper = styled.div<{ coverMenu?: boolean }>`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0 ${spacing.small};
  height: 100%;
  width: 100%;
  ${({ coverMenu }) =>
    coverMenu &&
    css`
      z-index: 5;
    `}
`;

export const Description = styled(Paragraph)`
  margin-bottom: ${spacing.mediumHigh};
`;

export const Title = styled(Heading)`
  margin-top: 10vh;
  margin-bottom: 10vh;
`;

export const SubTitle = styled(Heading)`
  margin-bottom: ${spacing.small};
`;

export const HomeButton = styled(AnchorButton)`
  margin-bottom: ${spacing.mediumHigh};
`;
