import styled from '@emotion/styled';
import { inputBorderStyle, inputFieldStyles } from '../../../../helpers/inputHelpers';
import { colors, spacing, typography } from '../../../../../themes';

export const DropdownHeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.tiny};
`;

export const DropdownLabelStyled = styled.div`
  ${typography.other.inputLabel};
  color: ${colors.primary.grey};
`;

export const DropdownPlaceholderStyled = styled.div<{ placholderActive: boolean }>`
  ${typography.body.large};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ placholderActive }) =>
    placholderActive ? colors.monochrome.grey50 : colors.base.black};
`;

export const StyledAdditionalSelectedWrapper = styled.div`
  margin-left: auto;
`;

export const SkeletonWrapper = styled.div`
  width: 100%;
  padding-right: ${spacing.xxsmall};
`;

export const ChevronWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`;

export const DropdownHeaderStyled = styled.button<{
  error?: boolean;
  open?: boolean;
}>`
  ${({ error }) => inputBorderStyle(!!error)}
  ${({ error }) => inputFieldStyles(!!error)}
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: ${spacing.xxsmall};
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.base.white};
  margin: 0;
  cursor: pointer;
  height: ${spacing.mediumHigh};
  width: 100%;
  outline: 0 !important;

  &:focus {
    outline: 0 !important;
    border-color: ${({ error }) => (error ? colors.alerts.error : colors.primary.grey)};
    padding: ${spacing.xmsmall} calc(${spacing.xmsmall} - 1px);
  }

  &:hover {
    border-color: ${({ error }) => (error ? colors.alerts.error : colors.primary.grey)};
  }

  &:disabled {
    background-color: ${colors.monochrome.grey10};
    color: ${colors.monochrome.grey60};
    border-color: ${colors.monochrome.grey40};
    cursor: not-allowed;
  }
`;

export const RequiredIndicator = styled.div`
  color: ${colors.primary.grey};
  ${typography.other.inputLabel}
`;

export const InfoIconWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
