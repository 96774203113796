import { useLocation } from 'react-router';
import { routePaths } from '#routes/routePaths';
import { isCurrentRoute } from '#utils/routeUtil';

const useHideBottomMenu = () => {
  const location = useLocation();

  const isIntroView = isCurrentRoute(routePaths.INTRODUCTION, location);
  const isLoginView = isCurrentRoute(routePaths.LOGIN, location);

  return isIntroView || isLoginView;
};

export default useHideBottomMenu;
