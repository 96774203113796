import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { colors, spacing } from '../../../themes';
import { type SkeletonLoaderProps } from '.';

export const SkeletonLoaderWrapper = styled.div<
  Pick<SkeletonLoaderProps, 'mt' | 'mb' | 'mr' | 'ml'>
>`
  width: 100%;
  margin-top: ${({ mt }) => formatValue(mt) ?? 'unset'};
  margin-bottom: ${({ mb }) => formatValue(mb) ?? 'unset'};
  margin-right: ${({ mr }) => formatValue(mr) ?? 'unset'};
  margin-left: ${({ ml }) => formatValue(ml) ?? 'unset'};
`;

export const Animation = styled.div<SkeletonLoaderProps>`
  width: ${({ width }) => formatValue(width) ?? '100%'};
  height: ${({ height }) => formatValue(height) ?? spacing.mediumLow};
`;

const LoaderGradientAnimation = keyframes`
    0% {
      background-position:0% 0%
    }  
    50%{
      background-position:100% 100%
    }
    100%{
      background-position:0% 0%
    }
`;

export const SkeletonLoaderAnimation = styled.div<SkeletonLoaderProps>`
  background: linear-gradient(
    90deg,
    ${colors.monochrome.grey20} 0%,
    ${colors.base.white} 54.17%,
    ${colors.monochrome.grey20} 97.92%
  );
  background-size: 200% 100%;
  height: 100%;

  animation: ${LoaderGradientAnimation} 3s ease infinite;
  border-radius: ${({ shape }) => getRadius(shape)};
`;

const getRadius = (shape?: string) => {
  switch (shape) {
    case 'circle':
      return '50%';
    case 'rounded':
      return spacing.tiny;
    case 'square':
    default:
      return 'unset';
  }
};

const formatValue = (value: string | number | undefined) => {
  if (!value) {
    return null;
  }

  if (typeof value === 'number') {
    return `${value}px`;
  }

  if (typeof value === 'string' && /px|em|rem|%|vh|vw/.test(value)) {
    return value;
  }

  return `${value}px`;
};
