import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import spacing from '#assets/spacing';

const BackButtonWrapper = styled(Link)`
  display: flex;
  padding-left: ${spacing.small};
`;

export default BackButtonWrapper;
