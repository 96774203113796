import styled from '@emotion/styled';
import colors from '#assets/colors';
import spacing from '#assets/spacing';
import Paragraph from '#components/Paragraph';

export const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.small};
`;

export const StyledParagraph = styled(Paragraph)`
  margin-top: ${spacing.small};
`;
