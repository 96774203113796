import { type ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type Location } from '@stenarecycling/customer-portal-types';
import { Country } from '#types/location';
import { useDialog } from '#utils/hooks/useDialog';
import { removeLeadingZeros } from '#utils/articleUtil';
import { ParagraphStyleType } from '#components/Paragraph';
import HazardousWasteInfo from './HazardousWasteInfo';
import WeightInputWrapper, {
  Content,
  WeightInput,
  KiloLabel,
  Title,
  HeadWrapper,
  InfoIcon,
  HazardousWasteInfoSweden,
} from './styles';

export type ArticleWeightProps = {
  id: string;
  handleOnSaveWeight: (id: string, weight: number) => void;
  selectedLocation: Location | null;
};

const WeightInputItem = ({ id, handleOnSaveWeight, selectedLocation }: ArticleWeightProps) => {
  const { t } = useTranslation();
  const { isOpen, open, close } = useDialog();
  const [inputWeight, setInputWeight] = useState<number>(0);
  const country: Country | undefined = selectedLocation?.country;

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = Number(parseInt(e.target.value));

    if (isNaN(value)) {
      value = 0;
    }

    setInputWeight(value);
  };

  useEffect(() => {
    handleOnSaveWeight(id, inputWeight);
  }, [handleOnSaveWeight, id, inputWeight]);

  return (
    <WeightInputWrapper>
      <Content>
        <HeadWrapper>
          <Title styleType={ParagraphStyleType.body16}>
            {t('placeOrder.hazardousWaste.input')}
          </Title>
          {country === Country.SWEDEN ? (
            <HazardousWasteInfoSweden
              aria-label="Anteckningskyldighet"
              data-testid="swefa"
              onClick={open}
            >
              <InfoIcon />
            </HazardousWasteInfoSweden>
          ) : (
            ''
          )}
        </HeadWrapper>
        <KiloLabel htmlFor="hazardous">kg</KiloLabel>
        <WeightInput
          id="hazardous"
          type="number"
          min="0"
          onChange={inputOnChange}
          value={removeLeadingZeros(inputWeight)}
        />
      </Content>
      <HazardousWasteInfo isOpen={isOpen} onClose={close} selectedLocation={selectedLocation} />
    </WeightInputWrapper>
  );
};

export default WeightInputItem;
