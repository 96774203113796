import styled from '@emotion/styled';
import { colors } from 'component-library';
import spacing from '#assets/spacing';

const Wrapper = styled.div`
  position: relative;
  padding: ${spacing.xxsmall} ${spacing.xsmall};
  left: -${spacing.xsmall};
  width: calc(100% + (${spacing.xsmall} * 2));
  background-color: ${colors.secondary.beigeTint1};
`;

const Message = styled.div<{ type?: string }>`
  display: flex;
  justify-content: center;
  padding: ${spacing.xxsmall} ${spacing.xxsmall} ${spacing.tiny};
  background-color: ${(props) => {
    switch (props.type) {
      case 'success':
        return colors.alerts.successTint3;

      case 'warning':
        return colors.alerts.warningTint3;

      case 'error':
        return colors.alerts.errorTint3;

      default:
        return colors.monochrome.grey20;
    }
  }};
`;

export { Wrapper, Message };
