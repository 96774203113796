import colors from '#assets/colors';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import { LinkListItemWrapper, ArrowBox, LinkAndDescriptionWrapper, StyledUserIcon } from './styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
type LinkListItemProps = {
  title: string;
  description?: string;
  to: string;
  state?: any;
};

const LinkListItem = ({ title, description, to, state, ...rest }: LinkListItemProps) => {
  return (
    <LinkListItemWrapper to={to} state={state ?? null} {...rest}>
      <LinkAndDescriptionWrapper>
        <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.black}>
          {title}
        </Paragraph>
        {description && (
          <Paragraph styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_00}>
            {description}
          </Paragraph>
        )}
      </LinkAndDescriptionWrapper>
      <ArrowBox>
        <StyledUserIcon />
      </ArrowBox>
    </LinkListItemWrapper>
  );
};

export default LinkListItem;
