import { type LinkProps } from 'react-router-dom';
import LeftArrowIcon from '#assets/icons/arrow-left.svg?react';
import BackButtonWrapper from './styles';

const BackButton = (props: LinkProps & React.RefAttributes<HTMLAnchorElement>) => {
  return (
    <BackButtonWrapper {...props} data-testid="BackButton">
      <LeftArrowIcon />
    </BackButtonWrapper>
  );
};

export default BackButton;
