import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import spacing from '#assets/spacing';

export const SpinnerWrapper = styled.div`
  height: ${spacing.mediumHigh};
  width: ${spacing.mediumHigh};
  overflow: visible;
`;

export const StyledMotionSvg = styled(motion.svg)`
  overflow: visible;
`;
