import { useEffect } from 'react';
import { useLocation } from 'react-router';
import PropTypes, { type InferProps } from 'prop-types';
import AppRoutes from '#routes';
import MainWrapper from './styles';

function Main({ updateServiceWorker }: InferProps<typeof Main.propTypes>) {
  updateServiceWorker(true); // Update service worker on rerender

  // Scrolls to top on react router navigation
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <MainWrapper id="MainWrapper" data-testid="MainWrapper">
      <AppRoutes />
    </MainWrapper>
  );
}

Main.propTypes = {
  updateServiceWorker: PropTypes.func.isRequired,
};

export default Main;
