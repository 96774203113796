import styled from '@emotion/styled';
import breakpoints from '#assets/breakpoints';
import colors from '#assets/colors';
import spacing from '#assets/spacing';
import fontSizes from '#assets/fontSizes';
import fontWeights from '#assets/fontWeights';
import Heading from '#components/Heading';
import Paragraph from '#components/Paragraph';

export const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const IntroImage = styled.div<{ backgroundImageUrl?: string }>`
  background-image: url(${({ backgroundImageUrl }) => backgroundImageUrl});
  height: 100%;
  min-height: 250px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: inherit;
    transition: inherit;
  }
`;

export const TextWrapper = styled.div`
  max-width: calc(${spacing.maxWidth} / 2);
  padding: ${spacing.mediumLow};
`;

export const IntroTitle = styled(Heading)`
  margin-top: ${spacing.xxsmall};
  margin-bottom: ${spacing.small};
`;

export const BulletPoints = styled.div`
  font-size: ${fontSizes.body16};
  color: ${colors.compCharcoal_00};
  line-height: 26px;
  letter-spacing: 0.5px;

  & ul {
    padding-left: ${spacing.small};
    margin: 0;
  }
`;

export const Nav = styled.nav`
  margin-top: ${spacing.xsmall};
  margin-bottom: ${spacing.small};
  padding: 0 ${spacing.mediumLow};
  max-width: calc(${spacing.maxWidth} / 2);
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0;
  }
`;

export const NoAccountParagraph = styled(Paragraph)`
  margin-bottom: ${spacing.mediumLow};
  padding: 0 ${spacing.mediumLow};
  text-align: center;
`;

export const NoAccountLink = styled.span`
  font-size: ${fontSizes.body18};
  font-weight: ${fontWeights.normal};
  color: ${colors.stenaBlue};
  line-height: 26px;
  letter-spacing: 0.5px;
  white-space: nowrap;
`;
