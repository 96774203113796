import dayjs from 'dayjs';
import { type Container, type SendOrderInfoCSFno } from '@stenarecycling/customer-portal-types';
import {
  type ArticleItemType,
  type ArticleGroupType,
  type RouteDate,
  type RouteInfo,
} from '#types/article';

const compareGroups = (group1: ArticleGroupType, group2: ArticleGroupType) => {
  if (group1.name < group2.name) {
    return -1;
  }
  if (group1.name > group2.name) {
    return 1;
  }

  return 0;
};

export const createGroups = (articles: ArticleItemType[]) => {
  const articleGroups: ArticleGroupType[] = [];

  articles.forEach((article) => {
    const firstChar = article.title.charAt(0).toLowerCase();
    const groupNames = articleGroups.map((group) => group.name);

    // check if group already exists
    let groupExisted = false;

    for (const groupName of groupNames) {
      if (firstChar === groupName) {
        const matchedGroup = articleGroups.find((group) => group.name === groupName);

        matchedGroup?.articles.push(article);
        groupExisted = true;
        break;
      }
    }
    // otherwise create group
    if (!groupExisted) {
      const newGroup = { name: firstChar, articles: [article] };

      articleGroups.push(newGroup);
    }
  });

  return articleGroups.sort(compareGroups);
};

export const removeLeadingZeros = (value: number | string) => {
  let valueAsString = value.toString();

  if (valueAsString.length > 1) {
    valueAsString = valueAsString.replace(/^0+/, '');
  }

  return valueAsString || '0';
};

export const createArticlePerContainer = (_article: ArticleItemType): ArticleItemType[] => {
  const { id, orderInfo: _orderInfo, ...rest } = _article;

  let { containers } = _article;

  if (!containers) {
    return [_article];
  }

  const orderInfo = _article.orderInfo as SendOrderInfoCSFno;

  if (orderInfo.availableContainerIds && orderInfo.availableContainerIds.length > 0) {
    containers = orderInfo.availableContainerIds.map((cont) => {
      return {
        ContainerId: cont,
        TypeDesc: cont,
        Countount: 1,
        ContainerNo: '1',
        Sequence: 1,
        ContainerTypeId: orderInfo.ContainerTypeId ?? '',
      } as Container;
    });
  }

  if (containers.length === 0) {
    return [];
  }

  let articles: ArticleItemType[] = [];

  for (let index = 0; index < containers.length; index++) {
    const orderInfo = {
      ..._orderInfo,
      ContainerId: containers[index].ContainerId?.toString() || null,
      ContainerNo: containers[index].ContainerNo?.toString() || null,
      ContainerSeq: containers[index].Sequence?.toString() || null,
    };

    const article: ArticleItemType = {
      ...rest,
      containers: undefined,
      id: `${id}-${containers[index].ContainerId}-${containers[index].Sequence}`,
      description: containers[index].TypeDesc || '',
      maxNumberOfItems: containers[index].Count || 0,
      orderInfo,
    };

    articles = [...articles, article];
  }

  return articles;
};

export const addSelectedRoute = (articles: ArticleItemType[]) =>
  articles.map((_article) => {
    const article = { ..._article };
    const { routesInfo } = article;

    if (routesInfo) {
      const nextBookableRoute = getNextAvailableRoutes(routesInfo).bookable;

      if (nextBookableRoute) {
        const { routeId, pickupDate } = nextBookableRoute;

        article.orderInfo = {
          ...article.orderInfo,
          SelectedRoute: {
            Id: routeId,
            Date: pickupDate,
          },
        };
      }
    }

    return article;
  });

export const shouldArticleBeIncluded = (routesInfo?: RouteInfo[]) => {
  // all none routed articles should always be included
  if (!routesInfo || routesInfo.length === 0) {
    return true;
  }

  let answer = false; // remains false if all routes have Scheduled === true

  for (const route of routesInfo) {
    if (route.RouteDates.some((_route: RouteDate) => _route.Scheduled === false)) {
      answer = true;
      break;
    }
  }

  return answer;
};

export const excludeArticlesWithNoBookableRoutes = (articles: ArticleItemType[]) =>
  articles.filter((article) => shouldArticleBeIncluded(article.routesInfo));

interface Route {
  routeId: number;
  pickupDate: string;
}

interface NextAvailableRoutes {
  scheduled: Route | null;
  bookable: Route | null;
}

// Get next available pickup route for a scheduled or bookable route if it exists
export const getNextAvailableRoutes = (routesInfo: RouteInfo[]): NextAvailableRoutes => {
  let firstAvailableScheduledRoute = null;
  let firstAvailableScheduledDate = '99';

  let firstAvailableBookableRoute = null;
  let firstAvailableBookableDate = '99';

  for (const route of routesInfo) {
    const scheduledRouteDate = route.RouteDates.find((routeDate) => routeDate.Scheduled);

    if (
      scheduledRouteDate &&
      scheduledRouteDate.Date.localeCompare(firstAvailableScheduledDate) < 0
    ) {
      firstAvailableScheduledDate = scheduledRouteDate.Date;
      firstAvailableScheduledRoute = {
        routeId: route.RouteId,
        pickupDate: scheduledRouteDate.Date,
      };
    }

    const bookableRouteDate = route.RouteDates.find(
      (routeDate) => !routeDate.Scheduled && !isDateTooClose(routeDate.Date),
    );

    if (bookableRouteDate && bookableRouteDate.Date.localeCompare(firstAvailableBookableDate) < 0) {
      firstAvailableBookableDate = bookableRouteDate.Date;
      firstAvailableBookableRoute = {
        routeId: route.RouteId,
        pickupDate: bookableRouteDate.Date,
      };
    }
  }

  return { scheduled: firstAvailableScheduledRoute, bookable: firstAvailableBookableRoute };
};

export const filterOutDeclaredDuplicates = (articles: ArticleItemType[]) =>
  articles.filter((article) => parseInt(article.id.split('-')[3]) <= 400);

//Determine if a route date is too close to order time
//(ex. same day at 12:00 or later)
const isDateTooClose = (routeDate: string): boolean => {
  const finalOrderHour = 12;
  const todayDate = dayjs().format('YYYY-MM-DD');
  const currentHour = dayjs().get('hour');

  return todayDate === routeDate && currentHour >= finalOrderHour;
};
