import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import colors from '#assets/colors';
import spacing from '#assets/spacing';
import CheckIcon from '#assets/icons/check.svg?react';
import Heading from '#components/Heading';

export const RejectedStatusTitle = styled(Heading)`
  color: ${colors.danger_00};
`;

export const OrderStatusWrapper = styled(motion.div)`
  padding: ${spacing.mediumLow} ${spacing.small};
  background-color: ${colors.compBeige_03};
`;

export const OrderListWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Row = styled(motion.div)`
  display: flex;
  align-items: baseline;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type OuterCircleProps = { active?: boolean };
export const OuterCircle = styled(motion.div)<OuterCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${spacing.small};
  width: ${spacing.small};
  border-radius: 50%;
  background-color: ${({ active }) => (active ? colors.compBlue_00 : colors.compBeige_01)};
`;

export const InnerCircleDot = styled(motion.div)`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${colors.compBeige_03};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${spacing.xsmall};
`;

export const SmallDotWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin: ${spacing.tiny} 0;
`;

export const SmallDot = styled(motion.div)`
  height: ${spacing.tiny};
  width: ${spacing.tiny};
  border-radius: 50%;
  background-color: ${colors.compBeige_01};
  margin: 2px 0;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 16px;
  height: auto;
`;
