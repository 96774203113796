import { useTranslation } from 'react-i18next';
import { motion, MotionConfig, useTransform, useScroll } from 'framer-motion';
import isPropValid from '@emotion/is-prop-valid';
import colors from '#assets/colors';
import { OrderStatusProccessStep, type StatusNumber } from '#utils/orderUtil';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import {
  OrderListWrapper,
  Row,
  Column,
  TextWrapper,
  InnerCircleDot,
  OuterCircle,
  SmallDotWrapper,
  SmallDot,
  StyledCheckIcon,
} from './styles';
type OrderStatusProps = {
  status?: string;
};

const OrderStatus = ({ status }: OrderStatusProps) => {
  const { t } = useTranslation();

  const startDelay = 0.5;
  const delay = 0.15;
  const animation = {
    animate: {
      transition: {
        delayChildren: startDelay,
        staggerChildren: delay * 6,
      },
    },
  };

  const circle = {
    initial: { opacity: 0.4 },
    animate: { scale: [1, 1.12, 1], opacity: 1 },
  };

  const dotWrapper = {
    animate: {
      transition: {
        delayChildren: delay + startDelay,
        staggerChildren: delay,
      },
    },
  };
  const dotWrapper2 = {
    animate: {
      transition: {
        delayChildren: delay * 7 + startDelay,
        staggerChildren: delay,
      },
    },
  };
  const dot = {
    animate: { scale: [1, 1.2, 1], opacity: 1 },
    initial: { opacity: 0 },
  };

  const row = { initial: { opacity: 0.3 }, animate: { opacity: 1 } };

  const isStepOne = () => OrderStatusProccessStep.isStepOne(status as StatusNumber);
  const isStepTwo = () => OrderStatusProccessStep.isStepTwo(status as StatusNumber);
  const isStepThree = () => OrderStatusProccessStep.isStepThree(status as StatusNumber);
  const isNoStatus = () => !OrderStatusProccessStep.statusIsAStep(status as StatusNumber);

  const { scrollY } = useScroll();
  const speed = 0.2;
  const maxLength = 35;
  const y = useTransform(scrollY, (inputY) => {
    const length = inputY * speed;

    if (length > maxLength) {
      return -maxLength;
    }

    return -length;
  });

  return (
    <MotionConfig isValidProp={isPropValid}>
      <OrderListWrapper variants={animation} animate="animate" initial="initial">
        <motion.div style={{ y }}>
          <Row variants={row}>
            <Column>
              <OuterCircle variants={circle} active={isStepOne()}>
                {(isStepOne() || isNoStatus()) && <InnerCircleDot variants={circle} />}
                {(isStepTwo() || isStepThree()) && <StyledCheckIcon />}
              </OuterCircle>
              <SmallDotWrapper variants={dotWrapper} animate="animate" initial="initial">
                <SmallDot variants={dot} />
                <SmallDot variants={dot} />
                <SmallDot variants={dot} />
                <SmallDot variants={dot} />
                <SmallDot variants={dot} />
              </SmallDotWrapper>
            </Column>
            <TextWrapper>
              <Paragraph
                fontColor={isStepOne() ? colors.compBlue_00 : colors.primGrey_01}
                styleType={ParagraphStyleType.body18}
                bold
              >
                {t('orderDetails.waiting.for.approval')}
              </Paragraph>
              <Paragraph
                styleType={ParagraphStyleType.body14}
                fontColor={isStepOne() ? colors.compCharcoal_00 : colors.primGrey_01}
              >
                {t('orderDetails.order.being.processed.message')}
              </Paragraph>
            </TextWrapper>
          </Row>

          <Row variants={row}>
            <Column>
              <OuterCircle variants={circle} active={isStepTwo()}>
                {isStepThree() ? <StyledCheckIcon /> : <InnerCircleDot variants={circle} />}
              </OuterCircle>
              <SmallDotWrapper variants={dotWrapper2} animate="animate" initial="initial">
                <SmallDot variants={dot} />
                <SmallDot variants={dot} />
                <SmallDot variants={dot} />
                <SmallDot variants={dot} />
                <SmallDot variants={dot} />
              </SmallDotWrapper>
            </Column>

            <TextWrapper>
              <Paragraph
                fontColor={isStepTwo() ? colors.compBlue_00 : colors.primGrey_01}
                styleType={ParagraphStyleType.body18}
                bold
              >
                {t('orderDetails.order.approved')}
              </Paragraph>
              <Paragraph
                styleType={ParagraphStyleType.body14}
                fontColor={isStepTwo() ? colors.compCharcoal_00 : colors.primGrey_01}
              >
                {t('orderDetails.order.approved.message')}
              </Paragraph>
            </TextWrapper>
          </Row>
          <Row variants={row}>
            <OuterCircle variants={circle}>
              {isStepThree() ? <StyledCheckIcon /> : <InnerCircleDot variants={circle} />}
            </OuterCircle>

            <TextWrapper>
              <Paragraph fontColor={colors.primGrey_01} styleType={ParagraphStyleType.body18} bold>
                {t('orderDetails.completed')}
              </Paragraph>
              <Paragraph styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_01}>
                {t('orderDetails.order.picked.up.message')}
              </Paragraph>
            </TextWrapper>
          </Row>
        </motion.div>
      </OrderListWrapper>
    </MotionConfig>
  );
};

export default OrderStatus;
