import { useTranslation } from 'react-i18next';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import { WarningContent, WarningWrapper } from './styles';

const DoubleBookingWarning = () => {
  const { t } = useTranslation();

  return (
    <WarningWrapper>
      <WarningContent>
        <Paragraph styleType={ParagraphStyleType.body16}>
          {t('placeOrder.doubleBooked.info')}
        </Paragraph>
      </WarningContent>
    </WarningWrapper>
  );
};

export default DoubleBookingWarning;
