import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { type Location } from '@stenarecycling/customer-portal-types';
import colors from '#assets/colors';
import { type OrderItemType } from '#types/order';
import { HeadingStyleType, HeadingType } from '#components/Heading';
import { ParagraphStyleType } from '#components/Paragraph';
import { BlockWrapper, BlockTitle, Row, Column, Label, Value } from '../styles';
import { Name, Description, Address } from './styles';

export type OrderDetailsBlockProps = {
  order?: OrderItemType | null;
  orderLocation?: Location;
};

const OrderDetailsBlock = ({ order, orderLocation }: OrderDetailsBlockProps) => {
  const { t } = useTranslation();

  const { id, name, description, address } = orderLocation ?? {};
  const { street, city } = address ?? {};

  return (
    <BlockWrapper>
      <BlockTitle headingType={HeadingType.H2} styleType={HeadingStyleType.Heading7} upperCase>
        {t('orderDetails.order.details')}
      </BlockTitle>

      <Label styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_00} upperCase>
        {t('orderDetails.pickup.location')}
      </Label>

      <Name styleType={ParagraphStyleType.body16}>{name}</Name>
      {description && (
        <Description styleType={ParagraphStyleType.body16}>{description}</Description>
      )}
      <Address styleType={ParagraphStyleType.body16}>{`${street}, ${city} (${id}) `}</Address>

      <Row>
        <Column>
          <Label styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_00} upperCase>
            {t('orderDetails.order.id')}
          </Label>
          <Value styleType={ParagraphStyleType.body16} data-testid="order-id">
            {`#${order?.id}`}
          </Value>
        </Column>
        <Column>
          <Label styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_00} upperCase>
            {t('orderDetails.date')}
          </Label>
          <Value styleType={ParagraphStyleType.body16}>
            {order && dayjs(order.createdDate).format('YYYY-MM-DD')}
          </Value>
        </Column>
      </Row>
    </BlockWrapper>
  );
};

export default OrderDetailsBlock;
