import { useTranslation } from 'react-i18next';
import colors from '#assets/colors';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import NoArticlesAvailableWrapper from './styles';

const NoArticlesAvailable = () => {
  const { t } = useTranslation();

  return (
    <NoArticlesAvailableWrapper>
      <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.primGrey_01}>
        {t('placeOrder.articles.noArticles')}
      </Paragraph>
    </NoArticlesAvailableWrapper>
  );
};

export default NoArticlesAvailable;
