import styled from '@emotion/styled';
import spacing from '#assets/spacing';
import Heading from '#components/Heading';

const ArticlesListWrapper = styled.div`
  padding: ${spacing.small} ${spacing.small};
  margin-bottom: ${spacing.medium};
`;

export const StyledHeading = styled(Heading)`
  margin-top: ${spacing.xxsmall};
  margin-bottom: ${spacing.mediumLow};
`;

export default ArticlesListWrapper;
