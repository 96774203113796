import axios, { type AxiosError, type AxiosRequestConfig } from 'axios';
import { environment } from '#helper/environment';
import { type SendOrderType, type OrderItemsDictionary } from '#types/order';
import { filterAndSortOrders } from '#utils/orderUtil';

export type SendOrderTypeWithToken = {
  token: string;
  transactionId: string;
} & SendOrderType;
export const postOrder = async (order: SendOrderTypeWithToken) => {
  const requestConfig: AxiosRequestConfig = {};

  if (order.token) {
    requestConfig.headers = {
      authorization: `Bearer ${order.token}`,
      'X-Transaction-ID': order.transactionId,
    };
  }

  return (
    axios
      .post(`${environment.newApi.url}${environment.newApi.path.orders}`, order, requestConfig)

      .then((response) => {
        return response.data;
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: Error | AxiosError<any>) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, statusText } = error.response as any;

          // Throw error returned from api if exists
          if (data?.error?.message) {
            throw Error(data.error.message);
          }

          // Otherwise just throw the statusText and data (body)
          throw Error(`${statusText}: ${data}`);
        }

        throw error;
      })
  );
};

export type GetOrdersParams = {
  token: string;
  transactionId: string;
  userEmail?: string;
  partnerId?: string;
  from?: string;
  to?: string;
};

export const getOrders = async ({
  token,
  transactionId,
  userEmail,
  partnerId,
  from,
  to,
}: GetOrdersParams) => {
  if (!userEmail && !partnerId) {
    throw new Error('getOrders input error');
  }

  const source = axios.CancelToken.source();

  const requestConfig: AxiosRequestConfig = {
    cancelToken: source.token,
  };

  if (token) {
    requestConfig.headers = { authorization: `Bearer ${token}`, 'X-Transaction-ID': transactionId };
  }

  const params = {
    ...(userEmail ? { userId: userEmail } : {}),
    ...(partnerId ? { partnerId } : {}),
    ...(from ? { from } : {}),
    ...(to ? { to } : {}),
  };

  return (
    axios
      .get<OrderItemsDictionary>(`${environment.newApi.url}${environment.newApi.path.orders}`, {
        ...requestConfig,
        params,
      })
      .then((response) => response.data)
      .then((orders) => {
        let resultOrders = {};

        for (const [locationId, locationOrders] of Object.entries(orders)) {
          const newEntry = { [locationId]: filterAndSortOrders(locationOrders) };

          resultOrders = { ...resultOrders, ...newEntry };
        }

        return resultOrders;
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: Error | AxiosError<any>) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data } = error.response as any;

          if (data && data.errors?.length > 0) {
            throw Error(data.errors[0].rs);
          }

          throw Error(data);
        }

        throw error;
      })
  );
};
