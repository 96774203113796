import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { spacing, colors, typography } from '../../../themes/stena-recycling';
import { type TabInnerProps, type TabsInnerProps } from './index';

const COLOR_INACTIVE_BORDER = colors.secondary.blueTint1;
const COLOR_SELECTED = colors.primary.blue;
const COLOR_BASE_ACTIVE = colors.base.black;
const COLOR_BEIGE = colors.monochrome.grey40;
const COLOR_BASE_INACTIVE = colors.primary.grey;

const ANIMATION_SETTING = 'all 120ms ease-in';

const SELECTED_LINE_HEIGHT_PX = 4; // px
const TAB_MARGIN = 8; // px

const STYLE_UL_LI_RESET = css`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const STYLE_BARS_INIT = css`
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: ${SELECTED_LINE_HEIGHT_PX}px;
    border-radius: ${SELECTED_LINE_HEIGHT_PX / 2}px;
    overflow: hidden;
    bottom: 0;
  }
`;

export const TabInner = styled.li<TabInnerProps>`
  ${() => STYLE_UL_LI_RESET}
  ${() => STYLE_BARS_INIT}
  position: relative;

  &::before {
    display: none !important; // Should never be visible
  }

  &::after {
    opacity: 0;
    width: 0;
    background-color: ${COLOR_BEIGE};
    bottom: -${SELECTED_LINE_HEIGHT_PX}px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &:hover::after {
    opacity: 1;
    width: 100%;
    ${({ animate }) => animate && `transition: ${ANIMATION_SETTING};`};
    ${({ disabled }) => disabled && `display: none;`}
  }

  & a {
    display: inline-block;
    box-sizing: border-box;
    text-decoration: none;
    color: ${({ active }) => (active ? COLOR_SELECTED : COLOR_BASE_INACTIVE)};
    ${({ disabled }) =>
      disabled &&
      `
      pointer-events: none; 
      color: ${COLOR_BEIGE};
      cursor: default;
    `}
    padding: ${spacing.tiny} ${spacing.xmsmall};
    font-family: ${typography.fontFamily};
    white-space: nowrap;
    user-select: none;
    ${typography.heading.desktop.small};
  }

  & a:hover {
    color: ${({ active }) => (active ? COLOR_SELECTED : COLOR_BASE_ACTIVE)};
    ${({ disabled }) =>
      disabled &&
      `
      pointer-events: none;
      color:${COLOR_BEIGE};
      cursor: default;
    `}
  }
`;

export const TabsWrapper = styled.div`
  position: relative;
  overflow-x: auto;
  max-width: 100%;
  width: 100%;
  display: inline-grid;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
`;

export const TabsInner = styled.ul<TabsInnerProps>`
  ${STYLE_UL_LI_RESET}
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify ?? 'start'};
  padding-bottom: ${SELECTED_LINE_HEIGHT_PX}px;
  position: relative;

  & li {
    margin: 0 ${TAB_MARGIN}px;
  }

  & li:first-of-type {
    margin-left: 0;
  }

  & li:last-of-type {
    margin-right: 0;
  }

  ${() => STYLE_BARS_INIT}
  &::before {
    width: 100%;
    left: 50%;

    transform: translate(-50%, 0);
    background-color: ${COLOR_INACTIVE_BORDER};
  }
  &::after {
    left: ${({ offsetLeft }) => offsetLeft}px;
    width: ${({ width }) => width || '0'}px;
    background-color: ${COLOR_SELECTED};
    ${({ animate }) => animate && `transition: ${ANIMATION_SETTING};`}
  }
`;
