import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ParagraphStyleType } from '@stenametall/component-library';
import { type Location } from '@stenarecycling/customer-portal-types';
import { Country } from '#types/location';
import { OrderStatusProccessStep, type StatusNumber } from '#utils/orderUtil';
import Heading, { HeadingStyleType, HeadingType } from '#components/Heading';
import Paragraph from '#components/Paragraph';
import PickUpDateWrapper from './styles';

type PickUpDateProps = {
  status?: string;
  latestPickupDate?: string;
  orderlocation?: Location;
  plannedPickupDate?: string;
};

const PickUpDate = ({
  status,
  latestPickupDate,
  orderlocation,
  plannedPickupDate,
}: PickUpDateProps) => {
  const { t } = useTranslation();

  if (OrderStatusProccessStep.isStepThree(status as StatusNumber)) {
    return null;
  }

  const renderPickUpDate = () => {
    if (OrderStatusProccessStep.isStepOne(status as StatusNumber)) {
      return t('orderDetails.order.status.waiting.approval');
    }

    if (OrderStatusProccessStep.isStepTwo(status as StatusNumber)) {
      const latestDate = latestPickupDate && new Date(latestPickupDate);

      if (orderlocation?.country === Country.SWEDEN) {
        return `${t('orderDetails.estimated.pickup.date')} ${dayjs(latestDate).format(
          'YYYY-MM-DD',
        )}`;
      }

      if (orderlocation?.country === Country.FINLAND) {
        const date = plannedPickupDate && new Date(plannedPickupDate);

        if (date) {
          return `${t('orderDetails.estimated.pickup.date')} ${dayjs(date).format('YYYY-MM-DD')}`;
        }

        return t('orderDetails.order.status.waiting.approval');
      }

      return `${t('orderDetails.latest.pickup.date')} ${dayjs(latestDate).format('YYYY-MM-DD')}`;
    }

    return 'N/A';
  };

  return (
    <PickUpDateWrapper>
      <Heading headingType={HeadingType.H2} styleType={HeadingStyleType.Heading7} upperCase>
        {t('orderDetails.pickup.date')}
      </Heading>
      <Paragraph styleType={ParagraphStyleType.body16}>{renderPickUpDate()}</Paragraph>
    </PickUpDateWrapper>
  );
};

export default PickUpDate;
