import { ExternalLink, Heading, Modal, Paragraph } from 'component-library';
import { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFlag } from '@unleash/proxy-client-react';
import { useEventTracking } from 'core/lib/hooks/useEventTracking';
import ModalImage from '../../assets/images/OPV2 - PWA.png';
import { navigateToPortalPickups } from './utils';
import { ModalContainer, ModalImageContainer, ModalList, ModalListItem, SubHeader } from './styles';

type LocalStorageObject =
  | {
      [key: string]: string;
      expires: string;
    }
  | undefined;

const setHasSeen = () => {
  const hasSeenObj = {
    hasSeen: 'true',
    expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7).toString(), // 7 days
  };

  localStorage.setItem('opv2-modal-seen', JSON.stringify(hasSeenObj));
};

export const OPV2Modal = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const ffOPV2Modal = useFlag('pwa-opv2-modal');
  const { trackEventAsync } = useEventTracking();

  const isPickupRoute = pathname.includes('place-order');

  const closeModal = () => {
    setIsModalOpen(false);

    setHasSeen();
  };

  const shouldShow = useMemo(() => {
    const localStorageObj = localStorage.getItem('opv2-modal-seen');

    if (!localStorageObj) {
      return isPickupRoute && ffOPV2Modal;
    }

    const hasSeenObj = JSON.parse(localStorageObj) as LocalStorageObject;
    const expires = hasSeenObj?.expires;
    let hasSeen = hasSeenObj?.hasSeen === 'true';

    if (expires) {
      const expiresDayJs = dayjs(expires);
      const nowDayJs = dayjs();

      if (expiresDayJs.isBefore(nowDayJs)) {
        console.log('expired');
        hasSeen = false;
      }
    }

    return isPickupRoute && !hasSeen && ffOPV2Modal;
  }, [isPickupRoute, ffOPV2Modal]);

  const [isModalOpen, setIsModalOpen] = useState(true);

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      {createPortal(
        <Modal
          show={isModalOpen}
          onClose={() => {
            closeModal();
          }}
          primaryAction={{
            label: t('order.pickups.on.portal.modal.cta.text'),
            action: async () => {
              setHasSeen();
              await trackEventAsync({ event: 'pwa_to_portal_click' });
              navigateToPortalPickups();
            },
            icon: <ExternalLink />,
          }}
          secondaryButton={t('order.pickups.on.portal.modal.cta.close')}
        >
          <ModalContainer>
            <ModalImageContainer>
              <img width={115} src={ModalImage} alt="" />
            </ModalImageContainer>
            <Heading as="h1" variant="large">
              {t('order.pickups.on.portal.modal.title')}
            </Heading>
            <div>
              <Trans
                i18nKey="order.pickups.on.portal.modal.text1"
                components={{
                  p: <Paragraph variant="large" />,
                  ul: <ModalList />,
                  li: <ModalListItem />,
                  subheader: <SubHeader />,
                }}
              />
            </div>
            <div>
              <Trans
                i18nKey="order.pickups.on.portal.modal.text2"
                components={{
                  p: <Paragraph variant="large" />,
                  ul: <ModalList />,
                  li: <ModalListItem />,
                  subheader: <SubHeader />,
                }}
              />
            </div>
          </ModalContainer>
        </Modal>,
        document.body,
      )}
    </>
  );
};
