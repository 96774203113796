import { createContext } from 'react';
import {
  type SegmentedButtonsProps,
  type SegmentedButtonsProviderProps,
} from '../types/segmentButtons';
import { useSegmentedButtonsActiveIndex } from '../hooks/useSegmentedButtonsActiveIndex';

export const SegmentedButtonsProvider = (props: SegmentedButtonsProviderProps) => {
  const { children } = props;

  const useActiveIndex = useSegmentedButtonsActiveIndex({
    value: props.selectedValue ?? '',
    onChange: props.onChange,
  });

  return (
    <SegmentedButtonsContexts.props.Provider value={props}>
      <SegmentedButtonsContexts.useCurrentIndex.Provider value={useActiveIndex}>
        {children}
      </SegmentedButtonsContexts.useCurrentIndex.Provider>
    </SegmentedButtonsContexts.props.Provider>
  );
};

export const SegmentedButtonsContexts = {
  props: createContext<SegmentedButtonsProps>({
    selectedValue: '',
    onChange: console.log,
    items: [],
  }),
  useCurrentIndex: createContext<ReturnType<typeof useSegmentedButtonsActiveIndex>>({
    selectedValue: '',
    setSelectedValue: console.log,
    onChange: console.log,
  }),
};
