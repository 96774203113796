import colors from '#assets/colors';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import ListItemWrapper from './styles';

type ListItemProps = {
  title: string;
  description?: string;
};

const ListItem = ({ title, description, ...rest }: ListItemProps) => {
  return (
    <ListItemWrapper {...rest}>
      <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.black}>
        {title}
      </Paragraph>
      {description && (
        <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.primGrey_00}>
          {description}
        </Paragraph>
      )}
    </ListItemWrapper>
  );
};

export default ListItem;
