import styled from '@emotion/styled';
import spacing from '#assets/spacing';
import colors from '#assets/colors';

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.xsmall} ${spacing.small};
  border-top: 1px solid ${colors.primGrey_03};
  &:last-child {
    border-bottom: 1px solid ${colors.primGrey_03};
  }
`;

export default ListItemWrapper;
