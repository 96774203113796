import * as Sentry from '@sentry/react';

export const saveToLocalStorage = (key: string, value: string): void => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    Sentry.captureException(e);
    console.warn(e);
  }
};

export const getFromLocalStorage = (key: string): string | undefined => {
  try {
    const serialisedState = localStorage.getItem(key);

    if (serialisedState === null) return undefined;

    return serialisedState;
  } catch (e) {
    Sentry.captureException(e);
    console.warn(e);

    return undefined;
  }
};

export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export type LocalStorageInstallPWADialogData = {
  lastShown: Date | string;
  timesShown: number;
  skippedFirstTime: Date | string;
};
export const saveInstallPWADialogData = (data: Partial<LocalStorageInstallPWADialogData>) => {
  try {
    localStorage.setItem('installPWADialogData', JSON.stringify(data));
  } catch (e) {
    Sentry.captureException(e);
    console.warn(e);
  }
};

export const getInstallPWADialogData = ():
  | Partial<LocalStorageInstallPWADialogData>
  | undefined => {
  try {
    const serialisedState = localStorage.getItem('installPWADialogData');

    if (serialisedState === null) return undefined;

    return JSON.parse(serialisedState);
  } catch (e) {
    Sentry.captureException(e);
    console.warn(e);

    return undefined;
  }
};
