import dayjs from 'dayjs';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker, {
  type ReactDatePickerCustomHeaderProps,
  type ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { sv } from 'date-fns/locale/sv';
import { da } from 'date-fns/locale/da';
import { fi } from 'date-fns/locale/fi';
import { pl } from 'date-fns/locale/pl';
import { nb } from 'date-fns/locale/nb';
import {
  DatePickerLabel,
  DatepickerWrapper,
  ErrorMessageBox,
  HighlightedDatesLabel,
  HighlightedDatesLabelWrapper,
  LabelWrapper,
  RequiredIndicator,
} from './styles';
import DatepickerCustomHeader from './subComponents/CustomHeaderDatepicker/CustomHeaderDatepicker';
import CustomInputDatepicker from './subComponents/CustomInputDatepicker/CustomInputDatepicker';
import './datepickerOverride.css';
import CustomFooterDatepicker from './subComponents/CustomFooterDatepicker/CustomFooterDatepicker';
import { CustomRangeInput } from './subComponents/CustomRangeInput/CustomRangeInput';
import { holidaysPerCountry } from './holidays';
import CustomHeaderMonthpicker from './subComponents/CustomHeaderMonthpicker';
registerLocale('sv', sv);
registerLocale('da', da);
registerLocale('fi', fi);
registerLocale('pl', pl);
registerLocale('no', nb);

export type DatepickerProps = Omit<ReactDatePickerProps, 'onChange'> & {
  monthPicker?: boolean;
  label?: string;
  enabledDates?: string[];
  highlightedDatesLabel?: string;
  highlightDates?: Date[];
  excludeWeekends?: boolean;
  enableRange?: boolean;
  disabled?: boolean;
  required?: boolean;
  isEditable?: boolean;
  showQuickRange?: boolean;
  disabledDates?: string[];
  onRangeChange?: (dates: [Date | null, Date | null]) => void;
  onChange?: (date: Date | null) => void;
  showHolidays?: boolean;
  errorMessage?: string;
  holidaysCountryCode?: 'SE' | 'NO' | 'FI' | 'DK' | 'PL';
};
export const Datepicker = (props: DatepickerProps) => {
  const {
    monthPicker,
    enabledDates,
    excludeWeekends,
    label,
    highlightDates,
    enableRange,
    isEditable,
    onChange,
    onRangeChange,
    showQuickRange,
    required,
    disabledDates,
    showHolidays,
    errorMessage,
    holidaysCountryCode,
    placeholderText,
    ...rest
  } = props;
  const [minDate, setMinDate] = useState<Date | undefined | null>();
  const [maxDate, setMaxDate] = useState<Date | undefined | null>();
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const datePickerRef = createRef<DatePicker>();
  const inputRef = useRef(null);
  const resolvedLanguage = i18n.resolvedLanguage ?? 'en';

  const filterDate = useMemo(() => {
    if (enabledDates || excludeWeekends || disabledDates) {
      const customDay = (date: Date) => {
        const dateStr = dayjs(date).format('YYYY-MM-DD');

        if (enabledDates && !enabledDates.includes(dateStr)) {
          return false;
        }
        if (disabledDates?.includes(dateStr)) {
          return false;
        }
        if (excludeWeekends && (date.getDay() === 0 || date.getDay() === 6)) {
          return false;
        }

        return true;
      };

      return customDay;
    } else {
      return undefined;
    }
  }, [enabledDates, excludeWeekends, disabledDates]);

  useEffect(() => {
    setMinDate(rest.minDate);
    setMaxDate(rest.maxDate);
    if (enabledDates && enabledDates.length > 0) {
      if (!rest.minDate) {
        const minDate = enabledDates[0];

        setMinDate(dayjs(minDate).toDate());
      }
      if (!rest.maxDate) {
        const maxDate = enabledDates[enabledDates.length - 1];

        setMaxDate(dayjs(maxDate).toDate());
      }
    }
  }, [enabledDates, rest.minDate, rest.maxDate]);

  const highlightedDates = (highlightDates: Date[] | undefined) => {
    if (highlightDates) {
      return [
        {
          'react-datepicker__day--highlighted-stena-elipse': highlightDates,
        },
      ];
    }

    return undefined;
  };

  const holidays = useMemo(() => {
    if (showHolidays && holidaysCountryCode) {
      return holidaysPerCountry[holidaysCountryCode].filter((holiday) => {
        return (
          dayjs(holiday.date).isAfter(dayjs()) &&
          dayjs(holiday.date).isBefore(dayjs().add(2, 'year')) // only show holidays for the next 2 years because of limitation in the datepicker
        );
      });
    }

    return undefined;
  }, [showHolidays, holidaysCountryCode]);

  const toggleOpen = () => {
    datePickerRef.current?.setOpen(!open);
    setOpen(!open);
  };

  const getDatepicker = () => {
    const props = {
      showMonthYearPicker: !!monthPicker,
      formatWeekDay: (name: string) => name.substring(0, 3),
      calendarStartDay: 1,
      locale: resolvedLanguage,
      dateFormat: 'yyyy-MM-dd',
      filterDate: filterDate,
      minDate: minDate,
      maxDate: maxDate,
      highlightDates: highlightedDates(highlightDates),
      renderCustomHeader: monthPicker
        ? (props: ReactDatePickerCustomHeaderProps) => (
            <CustomHeaderMonthpicker locale={resolvedLanguage} {...props} />
          )
        : (props: ReactDatePickerCustomHeaderProps) => (
            <DatepickerCustomHeader locale={resolvedLanguage} {...props} />
          ),
      renderDayContents: (day: number) => {
        return <span className="react-datepicker__day--inner">{day}</span>;
      },
      holidays,
      ref: datePickerRef,
    };

    if (enableRange && onRangeChange) {
      return (
        <DatePicker
          {...props}
          {...rest}
          disabled={rest.disabled}
          onChange={onRangeChange}
          customInput={
            <CustomRangeInput
              value={rest.value}
              toggleOpen={toggleOpen}
              updateRange={onRangeChange}
              minDate={rest.minDate}
              maxDate={rest.maxDate}
              disabled={rest.disabled}
            />
          }
          onCalendarClose={() => {
            setOpen(false);
          }}
          selectsRange
        >
          {showQuickRange && (
            <CustomFooterDatepicker
              monthPicker={monthPicker}
              onChange={(dates) => {
                datePickerRef.current?.setOpen(false);
                onRangeChange(dates);
              }}
            />
          )}
        </DatePicker>
      );
    } else {
      return (
        <DatePicker
          {...props}
          {...rest}
          disabled={rest.disabled}
          customInput={
            <CustomInputDatepicker
              ref={inputRef}
              editable={isEditable}
              disabled={rest.disabled}
              placeholderText={placeholderText}
            />
          }
          onChange={(date) => {
            if (onChange) onChange(date);
          }}
          calendarClassName={highlightDates && 'popper-extended'}
        >
          {showQuickRange && (
            <CustomFooterDatepicker
              onChange={(date) => {
                if (onChange) onChange(date[0]);
              }}
            />
          )}
          {highlightDates && (
            <HighlightedDatesLabelWrapper>
              <HighlightedDatesLabel>{rest.highlightedDatesLabel}</HighlightedDatesLabel>
            </HighlightedDatesLabelWrapper>
          )}
        </DatePicker>
      );
    }
  };

  return (
    <DatepickerWrapper>
      {label && (
        <LabelWrapper>
          <DatePickerLabel>{label}</DatePickerLabel>
          {required && <RequiredIndicator>*</RequiredIndicator>}
        </LabelWrapper>
      )}
      {getDatepicker()}
      {!!errorMessage && <ErrorMessageBox>{errorMessage}</ErrorMessageBox>}
    </DatepickerWrapper>
  );
};
