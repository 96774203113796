import styled from '@emotion/styled';
import { css } from '@emotion/react';
import colors from '#assets/colors';
import LinkButton from '#components/LinkButton';
import spacing from '#assets/spacing';

// filter is created for compBlue_00 color
const changeColorIfActive = ({ isActive }) =>
  isActive &&
  css`
    filter: invert(54%) sepia(90%) saturate(417%) hue-rotate(161deg) brightness(83%) contrast(81%);
  `;

const MenuButtonWrapper = styled(LinkButton)`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${colors.white};
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  img {
    height: 24px;
    width: 24px;
    margin-bottom: ${spacing.tiny};
    ${changeColorIfActive}
  }
`;

export default MenuButtonWrapper;
