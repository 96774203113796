import styled from '@emotion/styled';
import colors from '#assets/colors';
import spacing from '#assets/spacing';

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1380px;
  background-color: ${colors.white};
  height: ${spacing.headerHeight};
  border-bottom: 1px solid ${colors.primGrey_03};
  z-index: 3;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
`;

export const ImgWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    height: 31px;
    width: auto;
  }
`;

export const FlexItem = styled.div`
  flex: 1;

  //Alow the middle Item to grow
  &:nth-of-type(2) {
    flex: auto;
    justify-content: center;
  }

  display: flex;

  &:first-of-type > div {
    margin-right: auto;
  }
  &:last-of-type > div {
    margin-left: auto;
  }
`;
export default HeaderWrapper;
