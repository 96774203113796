import styled from '@emotion/styled';
import spacing from '#assets/spacing';
import Heading from '#components/Heading';

export const BranchContactInfoWrapper = styled.div`
  padding: ${spacing.small};
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: ${spacing.xsmall};
`;

export const ContactInfoSection = styled.div`
  margin-bottom: ${spacing.small};
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35vh;
  width: 100%;
`;
