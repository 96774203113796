import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Location } from '@stenarecycling/customer-portal-types';
import { type ArticleItemType } from '#types/article';
import { type RootState } from './store';

export type ConfirmationState = {
  articles: ArticleItemType[];
  location: Location | null;
};

export const initialConfirmationState: ConfirmationState = {
  articles: [],
  location: null,
};

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState: initialConfirmationState,
  reducers: {
    setConfirmationData: (state, action: PayloadAction<ConfirmationState>) => {
      const { articles, location } = action.payload;

      state.articles = articles;
      state.location = location;
    },
  },
});

export const selectConfirmationArticles = (state: RootState) => state.confirmation.articles;
export const selectConfirmationLocation = (state: RootState) => state.confirmation.location;
export const { setConfirmationData } = confirmationSlice.actions;
export default confirmationSlice.reducer;
