import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import colors from '#assets/colors';
import { HeadingStyleType, HeadingType } from '#components/Heading';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import ConfirmationFailedIcon from './ConfirmationFailedIcon';
import {
  OrderFailedWrapper,
  StyledHeading,
  StyledParagraph,
  BottomTextWrapper,
  TopPart,
} from './styles';

const OrderFailed = () => {
  const { t, i18n } = useTranslation();
  const [isDanish, setIsDanish] = useState(false);

  useEffect(() => {
    if (i18n.resolvedLanguage === 'da') {
      setIsDanish(true);
    }
  }, [i18n]);
  const Danish = () => {
    return (
      <TopPart>
        <ConfirmationFailedIcon />
        <StyledHeading headingType={HeadingType.H1} styleType={HeadingStyleType.Heading4} center>
          {t('confirmation.error.heading')}
        </StyledHeading>
        <StyledParagraph styleType={ParagraphStyleType.body18} center>
          {t('confirmation.error.text')}
        </StyledParagraph>
        <StyledParagraph styleType={ParagraphStyleType.body18} center>
          {t('confirmation.error.text0')}
        </StyledParagraph>
        <StyledParagraph styleType={ParagraphStyleType.body18} center>
          {t('confirmation.error.text1')}
        </StyledParagraph>
        <StyledParagraph styleType={ParagraphStyleType.body18} center>
          {t('confirmation.error.text2')}
        </StyledParagraph>
      </TopPart>
    );
  };

  const Other = () => {
    return (
      <>
        <TopPart>
          <ConfirmationFailedIcon />
          <StyledHeading headingType={HeadingType.H1} styleType={HeadingStyleType.Heading4} center>
            {t('confirmation.error.title')}
          </StyledHeading>
          <StyledParagraph styleType={ParagraphStyleType.body18} center>
            {t('confirmation.error.message')}
          </StyledParagraph>
        </TopPart>
        <BottomTextWrapper>
          <Paragraph styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_01} center>
            {t('confirmation.error.contact.message')}
          </Paragraph>
        </BottomTextWrapper>
      </>
    );
  };

  return <OrderFailedWrapper>{isDanish ? <Danish /> : <Other />}</OrderFailedWrapper>;
};

export default OrderFailed;
