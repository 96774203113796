import { useTranslation } from 'react-i18next';
import { HeadingStyleType, HeadingType } from '#components/Heading';
import { ParagraphStyleType } from '#components/Paragraph';
import ContainerIcon from './ContainerIcon';
import {
  ContentWrapper,
  NoLocationsAvailableWrapper,
  StyledHeading,
  StyledParagraph,
} from './styles';

const NoLocationsAvailable = () => {
  const { t } = useTranslation();

  return (
    <NoLocationsAvailableWrapper>
      <ContentWrapper>
        <ContainerIcon />
        <StyledHeading headingType={HeadingType.H2} styleType={HeadingStyleType.Heading4} center>
          {t('placeOrder.locations.noLocations')}
        </StyledHeading>
        <StyledParagraph styleType={ParagraphStyleType.body18} center>
          {t('placeOrder.locations.noLocations.message')}
        </StyledParagraph>
      </ContentWrapper>
    </NoLocationsAvailableWrapper>
  );
};

export default NoLocationsAvailable;
