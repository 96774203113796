import axios, { type AxiosRequestConfig } from 'axios';
import { environment } from '#helper/environment';

export const getBranchContact = async (token: string, transactionId: string, id: string) => {
  try {
    const requestConfig: AxiosRequestConfig = {
      params: {
        pickupPlaceId: id,
      },
    };

    if (token) {
      requestConfig.headers = {
        authorization: `Bearer ${token}`,
        'X-Transaction-ID': transactionId,
      };
    }

    return await axios.get(
      `${environment.newApi.url}${environment.newApi.path.branchContact}`,
      requestConfig,
    );
  } catch (error) {
    console.error(error);

    return;
  }
};
