import { type SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type Location } from '@stenarecycling/customer-portal-types';
import { useDialog } from '#utils/hooks/useDialog';
import { saveToLocalStorage, getFromLocalStorage } from '#utils/localStorageUtil';
import { HeadingType, HeadingStyleType } from '#components/Heading';
import Paragraph, { ParagraphStyleType } from '#components/Paragraph';
import LocationDialog from './LocationDialog';
import LocationWrapper, { LinkChangeLocation, StyledHeading } from './styles';

export type LocationProps = {
  updateSelectedLocation: (id: string) => void;
  initialLocation: Location | null;
  locations: Location[];
  buttonsRight?: boolean;
};

const LocationComponent = ({
  updateSelectedLocation,
  initialLocation,
  locations,
  buttonsRight,
}: LocationProps) => {
  const { t } = useTranslation();
  const { isOpen, open, close } = useDialog();
  const [selectedLocationId, setSelectedLocationId] = useState<string>(
    initialLocation ? initialLocation.dwKey : '',
  );

  const handleOnLocationChange = (e: SyntheticEvent) => {
    const { id } = e.currentTarget;

    setSelectedLocationId(id);
  };

  const handleOnSave = () => {
    updateSelectedLocation(selectedLocationId);
    saveToLocalStorage('selectedLocationId', selectedLocationId);
    close();
  };

  const locationExists = useCallback(
    (dwKey: string) => locations.some((location) => location.dwKey === dwKey),
    [locations],
  );

  useEffect(() => {
    if (isOpen && initialLocation) {
      setSelectedLocationId(initialLocation.dwKey);
    }
  }, [isOpen, initialLocation]);

  useEffect(() => {
    if (initialLocation) {
      setSelectedLocationId(initialLocation.dwKey);
    } else {
      const localStorageSelectedLocationId = getFromLocalStorage('selectedLocationId');

      if (localStorageSelectedLocationId && locationExists(localStorageSelectedLocationId)) {
        setSelectedLocationId(localStorageSelectedLocationId);
        updateSelectedLocation(localStorageSelectedLocationId);
      } else if (locations?.length > 0) {
        setSelectedLocationId(locations[0].dwKey);
        saveToLocalStorage('selectedLocationId', locations[0].dwKey);
        updateSelectedLocation(locations[0].dwKey);
      }
    }
  }, [updateSelectedLocation, initialLocation, locations, locationExists]);

  const { id = '', name = '', address } = initialLocation ?? {};
  const { street = '', city = '' } = address ?? {};

  return (
    <LocationWrapper data-testid="location-component">
      <StyledHeading styleType={HeadingStyleType.Heading6} headingType={HeadingType.H2} upperCase>
        {t('placeOrder.location')}
      </StyledHeading>
      <Paragraph styleType={ParagraphStyleType.body16}>{name}</Paragraph>
      <Paragraph styleType={ParagraphStyleType.body16}>{`${street}, ${city} (${id})`}</Paragraph>
      {locations.length > 1 && (
        <LinkChangeLocation data-testid="change-location" onClick={open}>
          {t('placeOrder.location.change')}
        </LinkChangeLocation>
      )}

      <LocationDialog
        locations={locations}
        isOpen={isOpen}
        onClose={close}
        handleOnSave={handleOnSave}
        initialLocationId={initialLocation?.dwKey}
        selectedLocationId={selectedLocationId}
        handleOnLocationChange={handleOnLocationChange}
        buttonsRight={buttonsRight}
      />
    </LocationWrapper>
  );
};

export default LocationComponent;
